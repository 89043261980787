import PropTypes from 'prop-types';
import React from 'react';
import toast from 'react-hot-toast';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { useRecoilState } from 'recoil';
import { auth as authState } from 'state/auth';

export default function CustomQueryClientProvider({ children }) {
  // Global state
  const [, setAuth] = useRecoilState(authState);

  // Create a client
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: async (error) => {
        const errorData = error?.response?.data;
        if (errorData?.token) {
          toast.error(errorData?.token);
          setAuth(null);
        }
      },
    }),
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </>
  );
}

CustomQueryClientProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
