import { PlusIcon } from '@heroicons/react/outline';
import { LocationMarkerIcon, SearchIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import LiItem from 'components/LiItem';
import Loader from 'components/Loader';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { FilterPsychologistSchema } from 'schemas/psychologist';
import { listPsychologists } from 'services/psychologists';
import { getToken } from 'state/auth';
import { isArrayEmpty } from 'utils/base';
import { SPANISH_MONTHS } from 'utils/constants';
import { PSYCHOLOGIST_DEGREES } from 'utils/psychologist';

export default function ListPatients() {
  // GLOBAL STATES
  const token = useRecoilValue(getToken);

  // LOCAL STATES
  const [filters, setFilters] = useState({
    search: '',
    isActive: true,
  });
  const [URL, setURL] = useState(null);

  // RRD
  const navigate = useNavigate();

  // RHF
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      search: '',
      isActive: true,
    },
    resolver: yupResolver(FilterPsychologistSchema),
  });

  // REACT QUERY
  const { data, isSuccess, isLoading, isRefetching, refetch } = useQuery(
    ['listPsychologists', token, filters, URL],
    () => listPsychologists(token, filters, URL),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    refetch();
  }, [filters, URL, refetch]);

  const onSubmit = (values) => {
    setURL(null);
    setFilters(values);
  };

  const onClickPsychologist = (id) => {
    navigate(`detail/${id}`);
  };

  const onClickPagination = (isNext) => {
    const URL = isNext ? data.next : data.previous;
    setURL(URL);
  };

  return (
    <BasicLayout
      title="Dovers"
      button={
        <div className="w-full lg:w-60">
          <Button
            className="rounded-md bg-primary text-white p-2"
            icon={<PlusIcon className="h-5 w-5 text-white mr-2" />}
            onClick={() => navigate('new')}
          >
            Crear Dover
          </Button>
        </div>
      }
    >
      <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-4">
        <div className="col-span-1 p-4 sticky">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <Input
              id="search"
              type="text"
              register={register}
              registerConfig={{}}
              icon={<SearchIcon className="h-6 w-6 text-gray-400" />}
              leftIcon={true}
              disabled={false}
            />

            <Controller
              name="isActive"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  id="isActive"
                  text="Está activo"
                  labelClasses="text-gray-500"
                  register={register}
                  registerConfig={{}}
                  checked={value}
                  onChange={onChange}
                  error={errors?.isAdmin?.message}
                />
              )}
            />

            <Button
              disabled={isLoading}
              type="submit"
              className="btn-tertiary rounded-md"
              icon={
                isLoading ? (
                  <>
                    <Loader className="text-white h-6 w-6" /> Procesando...
                  </>
                ) : null
              }
            >
              {isLoading ? '' : 'Buscar'}
            </Button>
          </form>
        </div>
        <div className="md:col-span-3 p-4">
          {isLoading || isRefetching ? (
            <div className="w-full flex justify-center items-center text-center font-body mt-10">
              <Loader /> Buscando...
            </div>
          ) : null}

          {isSuccess && isArrayEmpty(data.results) ? (
            <div className="text-center font-body mt-10">No se encontraron resultados</div>
          ) : null}

          {isSuccess && !isArrayEmpty(data.results) && (
            <>
              <ul className="divide-y divide-gray-100">
                {data.results.map((psychologist, index) => {
                  const createdDate = moment(
                    psychologist.created_at,
                    'DD-MM-YYYYTHH:mm:ssZ',
                  ).toDate();
                  const degree = PSYCHOLOGIST_DEGREES[psychologist.degree];
                  const location = `${psychologist.address?.state.name}, ${psychologist.address?.state.country.name}`;
                  const full_name = `${psychologist.first_name} ${psychologist.last_name}`;
                  return (
                    <LiItem
                      id={psychologist.id}
                      key={`psychologist-${index}`}
                      image={psychologist.photo_URL}
                      firstText={full_name}
                      secondText={
                        <>
                          Paciente desde{' '}
                          <strong>
                            {SPANISH_MONTHS[createdDate.getMonth()]} {createdDate.getFullYear()}
                          </strong>
                        </>
                      }
                      thirdText={location}
                      thirdTextIcon={
                        <LocationMarkerIcon className="ml-0 h-5 w-5 text-gray-400 mr-1" />
                      }
                      tag={degree.degree}
                      tagColor={degree.color}
                      withDot={degree.withDot}
                      onClick={() => onClickPsychologist(psychologist.id)}
                    />
                  );
                })}
              </ul>
            </>
          )}

          <nav
            className="mt-4 flex items-center justify-between border-t border-gray-200 bg-white py-3 "
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">1</span> to{' '}
                <span className="font-medium">10</span> of <span className="font-medium">20</span>{' '}
                results
              </p>
            </div>

            <div className="flex flex-1 justify-between sm:justify-end gap-4">
              <div className="w-30">
                <Button
                  disabled={data?.previous === null || isRefetching}
                  onClick={() => onClickPagination(false)}
                  className="rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Previous
                </Button>
              </div>
              <div className="w-30">
                <Button
                  disabled={data?.next === null || isRefetching}
                  onClick={() => onClickPagination(true)}
                  className="rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Next
                </Button>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </BasicLayout>
  );
}
