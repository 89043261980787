import {
  SESSION_CANCELED,
  SESSION_CREATED,
  SESSION_FINALIZED,
  SESSION_IN_PROGRESS,
  SESSION_PAYED,
  SESSION_SCHEDULED,
  SESSION_TIME_COMPLETED,
  SESSION_UNPAYED,
} from './statuses';

export const sessionStatuses = {
  [SESSION_CREATED]: {
    text: 'Creada',
    color: 'primary',
  },
  [SESSION_SCHEDULED]: {
    text: 'Agendada',
    color: 'secondary',
  },
  [SESSION_PAYED]: {
    text: 'Pagada',
    color: 'tertiary',
  },
  [SESSION_UNPAYED]: {
    text: 'Sin pago',
    color: 'fourth',
  },
  [SESSION_CANCELED]: {
    text: 'Cancelada',
    color: 'fifth',
  },
  [SESSION_IN_PROGRESS]: {
    text: 'En progreso',
    color: 'sixth',
  },
  [SESSION_TIME_COMPLETED]: {
    text: 'Tiempo completado',
    color: 'seventh',
  },
  [SESSION_FINALIZED]: {
    text: 'Finalizada',
    color: 'eighth',
  },
};
