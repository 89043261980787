const {
  TYPE_RESCHEDULE_SESSION,
  TYPE_CANCEL_SESSION,
  TYPE_CHANGE_ACCOMPANIMENT_PERIODICITY,
  TYPE_CANCEL_ACCOMPANIMENT,
  TYPE_REDUCE_ACCOMPANIMENT,
  TYPE_EXTEND_ACCOMPANIMENT,
  TYPE_STOP_ACCOMPANIMENT,
  STATUS_IN_REVIEW,
  STATUS_APPROVED,
  STATUS_REJECTED,
} = require('utils/constants/tickets');

export const TICKET_TYPES_MENU = [
  {
    id: TYPE_RESCHEDULE_SESSION,
    name: 'Reagendar sesión',
  },
  {
    id: TYPE_CANCEL_SESSION,
    name: 'Cancelar sesión',
  },
  {
    id: TYPE_CHANGE_ACCOMPANIMENT_PERIODICITY,
    name: 'Cambio de periodicidad de acompañamiento',
  },
  {
    id: TYPE_CANCEL_ACCOMPANIMENT,
    name: 'Cancelar Acompañamiento',
  },
  {
    id: TYPE_REDUCE_ACCOMPANIMENT,
    name: 'Reducir Acompañamiento',
  },
  {
    id: TYPE_EXTEND_ACCOMPANIMENT,
    name: 'Extender Acompañamiento',
  },
  {
    id: TYPE_STOP_ACCOMPANIMENT,
    name: 'Pausar Acompañamiento',
  },
];

export const TICKET_STATUSES_MENU = [
  {
    id: STATUS_IN_REVIEW,
    name: 'En revisión',
  },
  {
    id: STATUS_APPROVED,
    name: 'Aprobados',
  },
  {
    id: STATUS_REJECTED,
    name: 'Rechazados',
  },
];
