import 'moment-timezone';
import 'moment/locale/es';

import moment from 'moment';

import { capitalizeFirstLetter } from './base';

/**
  Function to get spanish date and timezone.
*/
export const getDateAndTimezone = (date) => {
  let currentTimezone = moment.tz.guess();

  let utcDate = date; // in utc
  let utcCutoff2 = moment.utc(utcDate, 'YYYYMMDD HH:mm:ss');
  let displayCutoff2 = utcCutoff2.clone().tz(currentTimezone);

  const sessionDate = displayCutoff2.format('LL');
  const sessionTime = displayCutoff2.format('hh:mm:ssa');
  return { sessionDate, sessionTime };
};

/**
  Function to format to yyyy-mm-dd calendar and make it a moment object.
  This function is specially to use it to print the selected dates in the
  airbnb react dates calendar component.
*/
export const getMomentDateCalendar = (date) => {
  if (!moment.isMoment(date)) return moment(moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD'));
};

/**
  Function to render customize month element.
  This function is specially to use it print and customize months in the
  airbnb react dates calendar component.
*/
export const renderMonthElement = (month) => {
  return (
    <strong className="text-black flex font-bold font-body">
      <small>{capitalizeFirstLetter(month.format('MMMM'))}</small>
    </strong>
  );
};

/**
  Function to see if the date is the same, needs to be a moment object.
  This function is specially to use to highlight the selected dates in the
  airbnb react dates calendar component.
*/
export const isSameDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  // Compare least significant, most likely to change units first
  // Moment's isSame clones moment inputs and is a tad slow
  return a.date() === b.date() && a.month() === b.month() && a.year() === b.year();
};

/**
  Function to render customize week element.
 This function is specially to use it print and customize the days of the week in the
  airbnb react dates calendar component.
*/
export const renderWeekHeaderElement = (day) => {
  return (
    <strong className="text-black mb-2 font-bold font-body">
      <small>{day}</small>
    </strong>
  );
};
