import { CalendarIcon, LocationMarkerIcon, MailIcon, PhoneIcon } from '@heroicons/react/solid';
import Avatar from 'assets/icons/Avatar';
import Tag from 'components/Tag';
import PropTypes from 'prop-types';
import React from 'react';

export default function CompleteProfile({
  textLabel,
  email,
  phone,
  image,
  completeName,
  location,
  sinceWhen,
  tag,
  tagColor,
  withDot,
  children,
  aboutMe,
  userRole,
}) {
  return (
    <div>
      <label className="text-lg font-semibold	font-body" htmlFor="contact">
        {textLabel}
      </label>
      <div
        className="border rounded-2xl relative filter cursor-pointer shadow-md"
        id="CompleteProfile"
      >
        <div className="flex flex-col items-center px-6 pt-8 pb-8">
          <div className="border-4 border-white">
            {image ? (
              <img className="object-cover rounded-full h-24 w-24 shadow-md" src={image} alt="" />
            ) : (
              <div className="h-24 w-24">
                <Avatar />
              </div>
            )}
          </div>
          <div className="mt-2 mb-1 flex justify-center items-center">
            <p className="font-body font-semibold text-lg text-center m-0">{completeName}</p>
          </div>
          <div className="mt-2 mb-1">
            <Tag tag={tag} color={tagColor} withDot={withDot} />
          </div>
          <div className="flex items-center my-2">
            <LocationMarkerIcon className="text-gray-400 h-6 w-6 mr-1" />
            <p className="font-body text-gray-500 m-0">{location}</p>
          </div>
          <div className="flex items-center my-2">
            <CalendarIcon className="text-gray-400 h-6 w-6 mr-1" />
            <p className="font-body text-gray-500">
              {userRole} desde <strong>{sinceWhen}</strong>
            </p>
          </div>
          <div className="flex items-center my-2">
            <MailIcon className="text-gray-400 h-6 w-6 mr-1" />
            <p className="font-body text-gray-500">{email}</p>
          </div>
          <div className="flex items-center my-2">
            <PhoneIcon className="text-gray-400 h-6 w-6 mr-1" />
            <p className="font-body text-gray-500">{phone}</p>
          </div>
          <div className="py-2 flex flex-col items-center justify-center">
            <p className="font-bold text-sm text-gray-500">Acerca de mi</p>
            <p className="font-body text-gray-500">{aboutMe}</p>
          </div>
        </div>
        <div className="w-full flex flex-col px-6 ">{children}</div>
      </div>
    </div>
  );
}

CompleteProfile.propTypes = {
  textLabel: PropTypes.string,
  completeName: PropTypes.string,
  location: PropTypes.string,
  tag: PropTypes.string,
  tagColor: PropTypes.string,
  sinceWhen: PropTypes.string,
  children: PropTypes.any,
  withDot: PropTypes.bool,
  image: PropTypes.any,
  bgImage: PropTypes.string,
  aboutMe: PropTypes.string,
  userRole: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
};

CompleteProfile.defaultProps = {
  tag: 'Abierto',
  tagColor: 'nineth',
  withDot: false,
  bgImage:
    'https://s3-alpha-sig.figma.com/img/20ac/d044/e166c8bed827179274b60cd68a86c26a?Expires=1642377600&Signature=YG4GoiwkeTzTPAKEL1EdeHAaIiKi4mQPqQYBLJtMSgoL0m~DUC-Rp8ia2wXu2~oIWBLvsepRqrS6wfgmmpLaCaVDIxuImdOx~~4OAkA62XFe37tMY02FM4AEGLjUhz25bAbvnpnOvBquYkFNqE20LnwUAdlasJDUHczvuWUtyVdQ5VYihg4CajNFzr2vQ--72viUndz~3pQWyVf-wBjz9m59V8XA1nnA-Y1bzsy~g-bW3nYbGfzuPrhRERwa2m1w5OKyBWXw0IrslEdEL0xi5k24fBiCCqjmlR-CPmX8tKAVv9AiYHKOUt-XnG4QaaS-p2l8KIqPKKxMGYiU-irt1g__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
  userRole: 'Paciente',
  email: '',
  phone: '',
};
