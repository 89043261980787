import { TrashIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import Button from './Button';
import ProfilePicture from './ProfilePicture';

export default function ProfrilePictureInput({ id, setValue, disabled, photoURL, prevImgSrc }) {
  const [imgSrc, setImgSrc] = useState(prevImgSrc);

  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleRemove = () => {
    setImgSrc(null);
    setValue(null);
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setValue(fileUploaded);
    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = function () {
      setImgSrc(reader.result);
    };
  };

  return (
    <div>
      <label htmlFor="ProfilePicture" className="block text-sm font-medium text-gray-700">
        Foto
      </label>
      <div className="mt-1 grid items-center">
        <span className="h-28 w-28 rounded-full overflow-hidden bg-gray-100">
          <ProfilePicture className="h-28 w-28" imgSrc={imgSrc ? imgSrc : photoURL} />
        </span>
        <div className="flex gap-4 mt-4">
          <Button
            disabled={disabled}
            type="button"
            primary
            onClick={handleClick}
            className="btn-primary rounded-md w-28 p-2"
          >
            {imgSrc ? 'Cambiar' : 'Cargar'}
          </Button>
          {imgSrc ? (
            <Button
              disabled={disabled}
              type="button"
              onClick={handleRemove}
              className="btn-error rounded-md w-12 p-2"
              icon={<TrashIcon className="h-4 w-4 text-white" />}
            ></Button>
          ) : null}
          <input
            disabled={disabled}
            id={id}
            ref={hiddenFileInput}
            type="file"
            className="hidden"
            multiple={false}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

ProfrilePictureInput.propTypes = {
  id: PropTypes.any,
  prevImgSrc: PropTypes.string,
  setValue: PropTypes.any,
  disabled: PropTypes.bool,
  photoURL: PropTypes.any,
};
