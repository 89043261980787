import { CalendarIcon, UserIcon, UsersIcon } from '@heroicons/react/solid';
import BasicLayout from 'components/BasicLayout';
// import Button from 'components/Button';
import Loader from 'components/Loader';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { retrievePsychologist } from 'services/psychologists';
import { getToken } from 'state/auth';

export default function Detail() {
  // GLOBAL STATES
  const token = useRecoilValue(getToken);

  // LOCAL STATES
  const [activeTab, setActiveTab] = useState(0);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  // ROUTER
  const navigate = useNavigate();

  // TABS
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tabs = [
    {
      name: 'Perfil',
      icon: UserIcon,
      onCallback: (id) => navigate(`/ic/psychologists/detail/${id}`),
    },
    {
      name: 'Pacientes',
      icon: UsersIcon,
      onCallback: (id) => navigate(`/ic/psychologists/detail/${id}/patients/`),
    },
    {
      name: 'Calendario',
      icon: CalendarIcon,
      onCallback: (id) => navigate(`/ic/psychologists/detail/${id}/calendar/`),
    },
  ];

  // PARAMS
  // const location = useLocation();
  const { id } = useParams();

  // REACT QUERY
  const { data, isSuccess, isLoading, error } = useQuery(['retrievePsychologist', token, id], () =>
    retrievePsychologist(token, id),
  );

  useEffect(() => {
    const selectedTab = tabs[`${activeTab}`];
    selectedTab.onCallback(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div>
      {/* Loader */}
      {isLoading && (
        <div className="text-center font-body">
          <Loader /> Procesando...
        </div>
      )}
      {/* Success */}
      {isSuccess ? (
        <BasicLayout title={data.first_name + ' ' + data.last_name}>
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                defaultValue={tabs[`${activeTab}`].name}
                onChange={(e) => setActiveTab(Number(e.target.value))}
              >
                {tabs.map((tab, index) => (
                  <option key={tab.name} value={index}>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab, index) => (
                    <button
                      key={tab.name}
                      onClick={() => setActiveTab(index)}
                      className={classNames(
                        activeTab === index
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm',
                      )}
                      aria-current={activeTab === index ? 'page' : undefined}
                    >
                      <tab.icon
                        className={classNames(
                          activeTab === index
                            ? 'text-indigo-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          '-ml-0.5 mr-2 h-5 w-5',
                        )}
                        aria-hidden="true"
                      />
                      <span>{tab.name}</span>
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <Outlet context={data} />
        </BasicLayout>
      ) : (
        // Error
        <div>{error}</div>
      )}
    </div>
  );
}
