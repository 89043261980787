export const userOptionsCustomSelect = [
  {
    id: 0,
    name: 'Inactivo',
    icon: (
      <span
        className="bg-red-600 flex-shrink-0 inline-block h-2 w-2 rounded-full"
        aria-hidden="true"
      ></span>
    ),
  },
  {
    id: 1,
    name: 'Activo',
    icon: (
      <span
        className="bg-green-400 flex-shrink-0 inline-block h-2 w-2 rounded-full"
        aria-hidden="true"
      ></span>
    ),
  },
];

export const accompanimentOptionsCustomSelect = [
  {
    id: 0,
    name: 'Creado',
    icon: (
      <span
        className="bg-tag_status_2 flex-shrink-0 inline-block h-2 w-2 rounded-full"
        aria-hidden="true"
      ></span>
    ),
  },
  {
    id: 1,
    name: 'Aceptado',
    icon: (
      <span
        className="bg-tag_status_4 flex-shrink-0 inline-block h-2 w-2 rounded-full"
        aria-hidden="true"
      ></span>
    ),
  },
  {
    id: 2,
    name: 'En progreso',
    icon: (
      <span
        className="bg-tag_status_3 flex-shrink-0 inline-block h-2 w-2 rounded-full"
        aria-hidden="true"
      ></span>
    ),
  },
  {
    id: 10,
    name: 'Cancelar',
    icon: (
      <span
        className="bg-tag_status_5 flex-shrink-0 inline-block h-2 w-2 rounded-full"
        aria-hidden="true"
      ></span>
    ),
  },
];
