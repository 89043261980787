import {
  REQUESTER_ADMIN,
  REQUESTER_HEAD_OF_MENTAL_HEALTH,
  REQUESTER_PATIENT,
  REQUESTER_PSYCHOLOGIST,
  STATUS_APPROVED,
  STATUS_IN_REVIEW,
  STATUS_REJECTED,
  TYPE_CANCEL_ACCOMPANIMENT,
  TYPE_CANCEL_SESSION,
  TYPE_CHANGE_ACCOMPANIMENT_PERIODICITY,
  TYPE_EXTEND_ACCOMPANIMENT,
  TYPE_REDUCE_ACCOMPANIMENT,
  TYPE_RESCHEDULE_SESSION,
  TYPE_STOP_ACCOMPANIMENT,
} from 'utils/constants/tickets';

export const TICKET_STATUSES = {
  [STATUS_IN_REVIEW]: { tag: 'En revisión', color: 'primary' },
  [STATUS_APPROVED]: { tag: 'Aprobado', color: 'tertiary' },
  [STATUS_REJECTED]: { tag: 'Rechazado', color: 'fifth' },
};

export const TICKET_TYPES = {
  [TYPE_RESCHEDULE_SESSION]: { tag: 'SE/Reagendar', color: 'primary' },
  [TYPE_CANCEL_SESSION]: { tag: 'SE/Cancelar', color: 'secondary' },
  [TYPE_CHANGE_ACCOMPANIMENT_PERIODICITY]: { tag: 'ACC/Periodicidad', color: 'tertiary' },
  [TYPE_CANCEL_ACCOMPANIMENT]: { tag: 'ACC/Cancelar', color: 'fourth' },
  [TYPE_REDUCE_ACCOMPANIMENT]: { tag: 'ACC/Reducir', color: 'fifth' },
  [TYPE_EXTEND_ACCOMPANIMENT]: { tag: 'ACC/Extender', color: 'sixth' },
  [TYPE_STOP_ACCOMPANIMENT]: { tag: 'ACC/Pausar', color: 'seven' },
};

export const TICKET_REQUESTERS = {
  [REQUESTER_PATIENT]: { tag: 'Paciente', color: 'primary' },
  [REQUESTER_PSYCHOLOGIST]: { tag: 'Dover', color: 'secondary' },
  [REQUESTER_HEAD_OF_MENTAL_HEALTH]: { tag: 'Head of mental health', color: 'tertiary' },
  [REQUESTER_ADMIN]: { tag: 'Administrador', color: 'fourth' },
};
