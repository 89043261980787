import 'moment/locale/es';

import Button from 'components/Button';
import FormHeader from 'components/FormHeader';
import Input from 'components/Input';
import PropTypes from 'prop-types';
import React from 'react';

export default function Experiences({ register, errors, disabled }) {
  return (
    <>
      <FormHeader title="Agregar Experiencia" />
      <p className="text-xs text-gray-400">
        Si continua con la experiencia, deje el campo <strong>Fecha de cierre</strong> vacio.
      </p>
      <br />

      <div className="py-2 px-4 rounded-md bg-gray-50 mb-4">
        <div className="flex flex-col md:flex-row my-2">
          <div className="flex-1 md:mr-2">
            <Input
              id={`title`}
              type="text"
              withLabel
              textLabel="Titulo"
              register={register}
              registerConfig={{}}
              error={errors?.title?.message}
            />
          </div>
          <div className="flex-1 md:ml-2">
            <Input
              id={`location`}
              type="text"
              withLabel
              textLabel="Ubicacion"
              register={register}
              registerConfig={{}}
              error={errors?.location?.message}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row my-2">
          <div className="flex-1 md:mr-2">
            <Input
              id={`company`}
              type="text"
              withLabel
              textLabel="Compania"
              register={register}
              registerConfig={{}}
              error={errors?.company?.message}
            />
          </div>
          <div className="flex-1 md:ml-2">
            <Input
              id={`charge`}
              type="text"
              withLabel
              textLabel="Cargo"
              register={register}
              registerConfig={{}}
              error={errors?.charge?.message}
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row my-2">
          <div className="flex-1 md:mr-2">
            <Input
              id={`start_date`}
              withLabel
              type="date"
              textLabel="Fecha de inicio"
              register={register}
              registerConfig={{}}
              error={errors?.start_date?.message}
            />
          </div>
          <div className="flex-1 md:ml-2">
            <Input
              id={`end_date`}
              withLabel
              type="date"
              textLabel="Fecha de cierre"
              register={register}
              registerConfig={{}}
              error={errors?.end_date?.message}
            />
          </div>
        </div>
      </div>
      <Button
        type="submit"
        disabled={disabled}
        primary={true}
        className="bg-secondary border text-white font-medium rounded-md text-sm my-4"
      >
        Guardar experiencias
      </Button>
    </>
  );
}

Experiences.propTypes = {
  register: PropTypes.func.isRequired,
  defaultExperience: PropTypes.object.isRequired,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
};

Experiences.defaultProps = {};
