import { DocumentDownloadIcon, LocationMarkerIcon, SearchIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import LiItem from 'components/LiItem';
import Loader from 'components/Loader';
import Select from 'components/Select';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { FilterPatientSchema } from 'schemas/patient';
import { listMktPatients, listPatients } from 'services/patient';
import { getToken } from 'state/auth';
import { isArrayEmpty } from 'utils/base';
import { SPANISH_MONTHS } from 'utils/constants';
import { STATUS_PATIENT } from 'utils/patient';

export default function ListPatients() {
  // GLOBAL STATES
  const token = useRecoilValue(getToken);

  // LOCAL STATES
  const [isLoadingMKTData, setIsLoadingMKTData] = useState(false);
  const [filters, setFilters] = useState({
    status: 3,
    search: '',
    isActive: true,
    isSuspended: false,
  });
  const [URL, setURL] = useState(null);

  // RRD
  const navigate = useNavigate();

  // RHF
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      status: 3,
      isActive: true,
      isSuspended: false,
    },
    resolver: yupResolver(FilterPatientSchema),
  });

  // REACT QUERY
  const { data, isLoading, isSuccess, isRefetching, refetch } = useQuery(
    ['listPatients', token, filters],
    () => listPatients(token, filters, URL),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    refetch();
  }, [filters, URL, refetch]);

  const onChangeSelect = (field, value) => {
    setValue(field, value);
  };

  const onSubmit = (values) => {
    setURL(null);
    setFilters(values);
  };

  const onClickPatient = (id) => {
    navigate(`detail/${id}`);
  };

  const onClickPagination = (isNext) => {
    const URL = isNext ? data.next : data.previous;
    setURL(URL);
  };

  // Function to trigger the download
  const downloadJson = async () => {
    setIsLoadingMKTData(true);
    const jsonData = await listMktPatients(token);
    if (jsonData) {
      const dataStr =
        'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(jsonData));
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      downloadAnchorNode.setAttribute('download', 'data.json');
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
      toast.success('Descarga exitosa.');
    } else {
      toast.error('Algo salio mal.');
    }
    setIsLoadingMKTData(false);
  };

  // TODO: manage errors
  return (
    <BasicLayout
      title="Pacientes"
      button={
        <div className="w-full lg:w-60">
          <Button
            disabled={isLoadingMKTData}
            className="rounded-md bg-primary text-white p-2"
            icon={
              isLoadingMKTData ? (
                <Loader className="text-white h-6 w-6" />
              ) : (
                <DocumentDownloadIcon className="h-5 w-5 text-white mr-2" />
              )
            }
            onClick={downloadJson}
          >
            Exportar Usuarios
          </Button>
        </div>
      }
    >
      <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-4">
        <div className="col-span-1 p-4 sticky">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <Input
              id="search"
              type="text"
              register={register}
              registerConfig={{}}
              icon={<SearchIcon className="h-6 w-6 text-gray-400" />}
              leftIcon={true}
              disabled={false}
            />

            <Select
              id="status"
              textLabel="Estatus"
              withIndex={false}
              selectOptions={[
                { id: 0, name: 'CREATED' },
                { id: 1, name: 'NO_ACTIVITIES' },
                { id: 3, name: 'IN_ACCOMPANIMENT' },
              ]}
              onChange={(e) => onChangeSelect('status', e.target.value)}
              register={register}
              registerConfig={{ required: true }}
              error={errors.degree?.message}
            />

            <Controller
              name="isActive"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  id="isActive"
                  text="Está activo"
                  labelClasses="text-gray-500"
                  register={register}
                  registerConfig={{}}
                  checked={value}
                  onChange={onChange}
                  error={errors?.isAdmin?.message}
                />
              )}
            />

            <Controller
              name="isSuspended"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  id="isAdmin"
                  text="Está suspendido"
                  labelClasses="text-gray-500"
                  register={register}
                  registerConfig={{}}
                  checked={value}
                  onChange={onChange}
                  error={errors?.isAdmin?.message}
                />
              )}
            />

            <Button
              disabled={isLoading}
              type="submit"
              className="btn-tertiary rounded-md"
              icon={
                isLoading ? (
                  <>
                    <Loader className="text-white h-6 w-6" /> Procesando...
                  </>
                ) : null
              }
            >
              {isLoading ? '' : 'Buscar'}
            </Button>
          </form>
        </div>
        <div className="md:col-span-3 p-4">
          {isLoading || isRefetching ? (
            <div className="w-full flex justify-center items-center text-center font-body mt-10">
              <Loader /> Buscando...
            </div>
          ) : null}

          {isSuccess && isArrayEmpty(data.results) ? (
            <div className="text-center font-body mt-10">No se encontraron resultados</div>
          ) : null}

          {isSuccess && !isArrayEmpty(data.results) && (
            <>
              <ul className="divide-y divide-gray-100">
                {data.results.map((patient, index) => {
                  const createdDate = moment(patient.created_at, 'DD-MM-YYYYTHH:mm:ssZ').toDate();
                  const status = STATUS_PATIENT[patient.status];
                  return (
                    <LiItem
                      id={patient.id}
                      key={`patient-${index}`}
                      image={patient.photo_URL}
                      firstText={patient.first_name + ' ' + patient.last_name}
                      secondText={
                        <>
                          Paciente desde{' '}
                          <strong>
                            {SPANISH_MONTHS[createdDate.getMonth()]} {createdDate.getFullYear()}
                          </strong>
                        </>
                      }
                      thirdText={
                        patient.address?.state.name + ' ' + patient.address?.state.country.name
                      }
                      thirdTextIcon={
                        <LocationMarkerIcon className="ml-0 h-5 w-5 text-gray-400 mr-1" />
                      }
                      tag={status.tag}
                      tagColor={status.tagColor}
                      withDot={status.withDot}
                      onClick={() => onClickPatient(patient.id)}
                    />
                  );
                })}
              </ul>
            </>
          )}

          <nav
            className="mt-4 flex items-center justify-between border-t border-gray-200 bg-white py-3 "
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">1</span> to{' '}
                <span className="font-medium">10</span> of <span className="font-medium">20</span>{' '}
                results
              </p>
            </div>

            <div className="flex flex-1 justify-between sm:justify-end gap-4">
              <div className="w-30">
                <Button
                  disabled={data?.previous === null || isRefetching}
                  onClick={() => onClickPagination(false)}
                  className="rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Previous
                </Button>
              </div>
              <div className="w-30">
                <Button
                  disabled={data?.next === null || isRefetching}
                  onClick={() => onClickPagination(true)}
                  className="rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Next
                </Button>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </BasicLayout>
  );
}
