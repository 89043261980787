import { CalendarIcon, LocationMarkerIcon, UserIcon } from '@heroicons/react/solid';
import ContactCard from 'components/ContactCard';
import Loader from 'components/Loader';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getPsychologistsPatients } from 'services/psychologists';
import { getToken } from 'state/auth';
import { isArrayEmpty } from 'utils/base';
import { SPANISH_MONTHS } from 'utils/constants';
import { STATUS_PATIENT } from 'utils/patient';

export default function Patients() {
  // Global states
  const token = useRecoilValue(getToken);

  // Params
  const { id } = useParams();

  // Router
  const navigate = useNavigate();

  // UseQuery
  const { data, isSuccess, isLoading } = useQuery(['getPsychologistsPatients', token, id], () =>
    getPsychologistsPatients(token, id),
  );

  return (
    <div>
      {isLoading && (
        <div className="text-center font-body">
          <Loader /> Buscando...
        </div>
      )}
      {isSuccess && isArrayEmpty(data) && (
        <div className="text-center font-body">
          <Loader /> No hay pacientes
        </div>
      )}
      {isSuccess && !isArrayEmpty(data) && (
        <div className="flex flex-col md:grid grid-cols-2 auto-rows-fr gap-x-3 box-border">
          {data.map((patient, index) => {
            const createdDate = moment(patient.created_at, 'DD-MM-YYYYTHH:mm:ssZ').toDate();

            const infoDescription = [
              {
                children: (
                  <p className={`font-body m-0 text-gray-500 text-xs`}>
                    {patient.address?.state.name}, {patient.address?.state.country.name}
                  </p>
                ),
                icon: <LocationMarkerIcon className="ml-0 h-5 w-5 text-gray-400 mr-2" />,
              },
              {
                children: (
                  <p className={`font-body m-0 text-gray-500 text-xs`}>
                    Paciente desde <strong>{SPANISH_MONTHS[createdDate.getMonth()]}</strong>
                  </p>
                ),
                icon: <CalendarIcon className="ml-0 h-5 w-5 text-gray-400 mr-2" />,
              },
            ];
            return (
              <div key={index} className="my-4 border rounded-lg shadow-md">
                <ContactCard
                  title={patient.first_name + ' ' + patient.last_name}
                  navigate={navigate}
                  buttonInfo={[
                    {
                      text: 'Detalle',
                      icon: <UserIcon className="ml-0 h-5 w-5 text-primary mr-2" />,
                      onClickEvent: () => {
                        navigate(`/ic/patients/detail/${patient.id}`);
                      },
                    },
                  ]}
                  infoDescription={infoDescription}
                  tag={STATUS_PATIENT[patient.status].tag}
                  tagColor={STATUS_PATIENT[patient.status].tagColor}
                  withDot={STATUS_PATIENT[patient.status].withDot}
                >
                  <img className="rounded-full h-10 w-10" src={patient.photo_URL} alt="" />
                </ContactCard>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
