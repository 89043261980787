import * as Yup from 'yup';

// CONSTANTS
const requiredField = 'Este campo es obligatorio';

// Schema to export payment tickets
export const ExportPaymentTickesSchema = Yup.object().shape({
  email: Yup.string().email('Correo electrónico invalido').required(requiredField),
  date: Yup.date().required(requiredField),
  status: Yup.number().oneOf([1, 2, 3]).required(requiredField),
  fortnight: Yup.number().oneOf([1, 2]).required(requiredField),
});
