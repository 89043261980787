import { yupResolver } from '@hookform/resolvers/yup';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import LiItem from 'components/LiItem';
import Loader from 'components/Loader';
import Select from 'components/Select';
import Title from 'components/Title';
import moment from 'moment';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AccompanimentUpdateSchema } from 'schemas/accompaniment';
import {
  getPrivateAccompaniment as getPrivateAccompanimentService,
  listSessions,
  updateAccompaniment,
} from 'services/accompaniment';
import { getToken } from 'state/auth';
import { ACCOMPANIMENT_STATUSES_MENU } from 'utils/menus/accompaniment';
import { sessionStatuses } from 'utils/parsers';

export default function AccompanimentDetail() {
  // Global State
  const token = useRecoilValue(getToken);

  // PARAMS
  const { id } = useParams();

  // RRD
  const navigate = useNavigate();

  // RHF
  const {
    reset,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      status: 0,
    },
    resolver: yupResolver(AccompanimentUpdateSchema),
  });

  const retrieveAccompanimentQuery = useQuery(
    ['getPrivateAccompaniment', token, id],
    () => getPrivateAccompanimentService(token, id),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        reset({ status: data.status });
      },
    },
  );
  const listSessionsQuery = useQuery(['listSessions', token, id], () => listSessions(token, id), {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

  const updateAccompanimentMutation = useMutation(
    (data) => {
      updateAccompaniment(token, id, data);
    },
    {
      onSuccess: () => toast.success('Se guardo la información con exito.'),
      onError: () => toast.error('Algo salio mal.'),
    },
  );

  const onChangeSelect = (field, value) => {
    setValue(field, value);
  };

  const onSubmit = (values) => {
    updateAccompanimentMutation.mutate(values);
  };

  const onClickSession = (id) => {
    navigate(`/ic/sessions/${id}`);
  };

  const buildSession = (session, index) => {
    const title = `Sesión #${session.number}`;

    const format = 'DD [de] MMMM [del] YYYY [a las] HH:mm a';
    const startDate = moment(session.start_date).format(format);
    const endDate = moment(session.end_date).format(format);
    const parsedStatus = sessionStatuses[String(session.status)];

    return (
      <LiItem
        id={session.id}
        key={`session-${index}`}
        firstText={title}
        secondText={startDate}
        thirdText={endDate}
        tag={parsedStatus.text}
        tagColor={parsedStatus.color}
        withDot={false}
        onClick={() => onClickSession(session.id)}
      />
    );
  };

  let patient = null;
  let psychologist = null;
  let nextSession = null;
  let startDateUTC = null;
  let endDateUTC = null;
  let patientStartDate = null;
  let patientEndDate = null;
  let psychologistStartDate = null;
  let psychologistEndDate = null;
  let guestStartDate = null;
  let guestEndDate = null;
  const format = 'DD/MM/YYYY H:mm';
  const localTimezone = moment.tz.guess(true);
  const localCountryCode = moment.tz.zone(localTimezone).countries()[0];
  if (retrieveAccompanimentQuery.isSuccess) {
    patient = retrieveAccompanimentQuery.data.patient;
    psychologist = retrieveAccompanimentQuery.data.psychologist;
    nextSession = retrieveAccompanimentQuery.data.next_session;
    startDateUTC = moment(nextSession.start_date).utc();
    endDateUTC = moment(nextSession.end_date).utc();
    patientStartDate = startDateUTC.tz(patient.timezone).format(format);
    patientEndDate = endDateUTC.tz(patient.timezone).format(format);
    psychologistStartDate = startDateUTC.tz(psychologist.timezone).format(format);
    psychologistEndDate = endDateUTC.tz(psychologist.timezone).format(format);
    guestStartDate = startDateUTC.tz(localTimezone).format(format);
    guestEndDate = endDateUTC.tz(localTimezone).format(format);
  }

  return (
    <BasicLayout title={`Detalle de Acompañamiento #${id}`}>
      {retrieveAccompanimentQuery.isLoading && listSessionsQuery.isLoading ? (
        <div className="flex">
          <div className="mt-4 w-full flex justify-center items-center">
            <Loader className="h-12 w-12 text-primary" />
          </div>
        </div>
      ) : null}
      <div className="grid grid-cols-1 lg:grid-cols-2 items-baseline gap-4">
        {retrieveAccompanimentQuery.isSuccess ? (
          <div className="grid grid-cols-1">
            {/* TIMEZONE */}
            <div className="lg:col-span-1 grid grid-cols-1 gap-4 mt-4">
              <div className="grid gap-1">
                <p className="mr-1 text-lg font-header text-gray-500 ">Dover:</p>
                <p className="mr-1 text-gray-600 font-bold">
                  {psychologist.full_name}, {psychologist.country.name}{' '}
                  <ReactCountryFlag countryCode={psychologist.country.code} />
                </p>
              </div>
              <div className="grid gap-1">
                <p className="mr-1 text-lg font-header text-gray-500 ">Patient:</p>
                <p className="mr-1 text-gray-600 font-bold">
                  {patient.full_name}, {patient.country.name}{' '}
                  <ReactCountryFlag countryCode={patient.country.code} />
                </p>
              </div>
              <div className="grid gap-1">
                <p className="mr-1 text-lg font-header text-gray-500 ">Guest:</p>
                <p className="mr-1 text-gray-600 font-bold">
                  Admin <ReactCountryFlag countryCode={localCountryCode} />
                </p>
              </div>
              <div className="grid gap-4">
                <p className="mr-1 text-lg font-header text-gray-500">Horarios Siguiente sesión:</p>
                <div className="grid grid-cols-1 gap-4">
                  <div className="flex items-center">
                    <p className="mr-2 text-gray-400 ">
                      {`${psychologistStartDate} - ${psychologistEndDate} (${psychologist.timezone})`}
                    </p>
                    <ReactCountryFlag countryCode={psychologist.country.code} />
                  </div>
                  <div className="flex items-center">
                    <p className="mr-2 text-gray-400 ">
                      {`${patientStartDate} - ${patientEndDate} (${patient.timezone})`}
                    </p>
                    <ReactCountryFlag countryCode={patient.country.code} />
                  </div>
                  <div className="flex items-center">
                    <p className="mr-2 text-gray-400 ">{`${guestStartDate} - ${guestEndDate} (${localTimezone})`}</p>
                    <ReactCountryFlag countryCode={localCountryCode} />
                  </div>
                </div>
              </div>
            </div>

            {/* UPDATE */}
            <div className="lg:col-span-1 grid grid-cols-1 pl-0 p-8 mt-8 lg:mt-0">
              <div className="col-span-1">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                  <Select
                    id="status"
                    textLabel="Estatus"
                    withIndex={false}
                    selectOptions={ACCOMPANIMENT_STATUSES_MENU}
                    onChange={(e) => onChangeSelect('status', e.target.value)}
                    register={register}
                    registerConfig={{ required: true }}
                    error={errors.status?.message}
                  />

                  <Button
                    disabled={updateAccompanimentMutation.isLoading}
                    type="submit"
                    className="btn-tertiary rounded-md"
                    icon={
                      updateAccompanimentMutation.isLoading ? (
                        <>
                          <Loader className="text-white h-6 w-6" /> Editando...
                        </>
                      ) : null
                    }
                  >
                    {updateAccompanimentMutation.isLoading ? '' : 'Editar'}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        ) : null}
        {listSessionsQuery.isSuccess && listSessionsQuery.data.length ? (
          <div className="relative">
            <Title className=" text-gray-500">Sesiones</Title>
            <div className="grid overflow-y-scroll">{listSessionsQuery.data.map(buildSession)}</div>
          </div>
        ) : null}
      </div>

      <br />
    </BasicLayout>
  );
}
