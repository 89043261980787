import * as Yup from 'yup';

// CONSTANTS
const requiredField = 'Este campo es obligatorio';

// SCHEMAS
// Schema to retrieve and update accompaniment
export const AccompanimentSchema = Yup.object().shape({
  title: Yup.string().required(requiredField),
  description: Yup.string().required(requiredField),
});

export const FilterAccompanimentSchema = Yup.object().shape({
  search: Yup.string(),
  status: Yup.string(),
});

export const AccompanimentUpdateSchema = Yup.object().shape({
  status: Yup.number(),
});
