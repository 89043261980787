import Avatar from 'assets/icons/Avatar';
import PropTypes from 'prop-types';
import React from 'react';

export default function ProfilePicture({ imgSrc, className, borderWhite }) {
  /**
   * If you made the img absolute, the father needs to be relative
   */

  if (!imgSrc) {
    return <Avatar />;
  }

  return (
    <img
      className={`object-cover rounded-full  ${
        borderWhite && 'border-white border-2'
      } ${className}`}
      src={imgSrc}
      alt=""
    />
  );
}

ProfilePicture.propTypes = {
  imgSrc: PropTypes.string,
  className: PropTypes.string,
  borderWhite: PropTypes.bool.isRequired,
};

ProfilePicture.defaultProps = {
  className: 'h-11 w-11 ',
  borderWhite: true,
};
