import * as Yup from 'yup';

// CONSTANTS
const requiredField = 'Este campo es obligatorio';

// SCHEMAS

export const NewExperienceSchema = Yup.object().shape({
  title: Yup.string().required(requiredField),
  location: Yup.string().required(requiredField),
  company: Yup.string().required(requiredField),
  charge: Yup.string().required(requiredField),
  start_date: Yup.date().required(requiredField).typeError(requiredField),
  end_date: Yup.date()
    .nullable()
    .default(null)
    .transform((curr, orig) => (orig === '' ? null : curr)),
});

export const NewEducationSchema = Yup.object().shape({
  title: Yup.string().required(requiredField),
  location: Yup.string().required(requiredField),
  institute: Yup.string().required(requiredField),
  start_date: Yup.date().required(requiredField).typeError(requiredField),
  end_date: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
});

// Schema to create a new psychologist
export const NewPsychologistSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredField),
  lastName: Yup.string().required(requiredField),
  degree: Yup.number().required(requiredField),
  email: Yup.string().email('Correo electrónico invalido').required(requiredField),
  video: Yup.string().url('Ingrese una url valida').required(requiredField),
  biography: Yup.string().required(requiredField),
  reason: Yup.string().required(requiredField),
  tags: Yup.array().required('Seleccione al menos un tag.'),
  address: Yup.object().shape({
    country: Yup.number().required(requiredField),
    state: Yup.number().required(requiredField),
    timezone: Yup.number().required(requiredField),
    city: Yup.string().required(requiredField),
    street: Yup.string().required(requiredField),
    extNumber: Yup.string().required(requiredField),
    intNumber: Yup.string(),
    zipCode: Yup.string().required(requiredField),
  }),
  experiences: Yup.array().of(NewExperienceSchema).min(1, 'Debe agregar al menos una experiencía'),
  educations: Yup.array()
    .of(NewEducationSchema)
    .min(1, 'Debe agregar al menos un item de educación'),
});

// Schema to retrieve and update psychologist
export const UpdatePsychologistSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  aboutMe: Yup.string(),
  email: Yup.string().email('Correo electrónico invalido'),
  degree: Yup.number(),
  video: Yup.string().url(),
  reason: Yup.string(),
  bank_account: Yup.string(),
  // address: Yup.object().shape({
  //   country: Yup.number(),
  //   state: Yup.number(),
  //   timezone: Yup.number(),
  //   aka: Yup.string(),
  //   city: Yup.string(),
  //   street: Yup.string(),
  //   extNumber: Yup.string(),
  //   intNumber: Yup.string(),
  //   zipCode: Yup.string(),
  //   default: Yup.boolean(),
  // }),
  // experiences: Yup.array(),
});

export const FilterPsychologistSchema = Yup.object().shape({
  search: Yup.string(),
  isActive: Yup.bool(),
});
