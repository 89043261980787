import * as Yup from 'yup';

// CONSTANTS
const requiredField = 'Este campo es obligatorio';

// Schema to create a new hop
export const CreateHopSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredField),
  lastName: Yup.string(),
  email: Yup.string().email('Correo electrónico invalido'),
});
