import { ClockIcon } from '@heroicons/react/outline';
import { SearchIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import Input from 'components/Input';
import LiItem from 'components/LiItem';
import Loader from 'components/Loader';
import Select from 'components/Select';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { FilterTicketSchema } from 'schemas/tickets';
import { list as listTicketsService } from 'services/tickets';
import { getToken } from 'state/auth';
import { isArrayEmpty } from 'utils/base';
import { STATUS_IN_REVIEW, TYPE_RESCHEDULE_SESSION } from 'utils/constants/tickets';
import { TICKET_STATUSES_MENU, TICKET_TYPES_MENU } from 'utils/menus/tickets';
import { TICKET_REQUESTERS, TICKET_TYPES } from 'utils/parsers/tickets';

const initialState = {
  status: STATUS_IN_REVIEW,
  type: TYPE_RESCHEDULE_SESSION,
  search: '',
};

export default function ListTickets() {
  // GLOBAL STATES
  const token = useRecoilValue(getToken);

  // LOCAL STATES
  const [filters, setFilters] = useState(initialState);
  const [URL, setURL] = useState(null);

  // RRD
  const navigate = useNavigate();

  // RHF
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialState,
    resolver: yupResolver(FilterTicketSchema),
  });

  // REACT QUERY
  const { data, isLoading, isSuccess, isRefetching, refetch } = useQuery(
    ['listTickets', token, filters],
    () => listTicketsService(token, filters, URL),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    refetch();
  }, [filters, URL, refetch]);

  const onChangeSelect = (field, value) => {
    setValue(field, value);
  };

  const onSubmit = (values) => {
    setURL(null);
    setFilters(values);
  };

  const onClickTickets = (id) => {
    navigate(`detail/${id}`);
  };

  const onClickPagination = (isNext) => {
    const URL = isNext ? data.next : data.previous;
    setURL(URL);
  };

  return (
    <BasicLayout title="Pacientes">
      <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-4">
        <div className="col-span-1 p-4 sticky">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <Input
              id="search"
              type="text"
              register={register}
              registerConfig={{}}
              icon={<SearchIcon className="h-6 w-6 text-gray-400" />}
              leftIcon={true}
              disabled={false}
            />

            <Select
              id="type"
              textLabel="Tipo"
              withIndex={false}
              selectOptions={TICKET_TYPES_MENU}
              onChange={(e) => onChangeSelect('type', e.target.value)}
              register={register}
              registerConfig={{ required: true }}
              error={errors.type?.message}
            />

            <Select
              id="status"
              textLabel="Estatus"
              withIndex={false}
              selectOptions={TICKET_STATUSES_MENU}
              onChange={(e) => onChangeSelect('status', e.target.value)}
              register={register}
              registerConfig={{ required: true }}
              error={errors.status?.message}
            />

            <Button
              disabled={isLoading}
              type="submit"
              className="btn-tertiary rounded-md"
              icon={
                isLoading ? (
                  <>
                    <Loader className="text-white h-6 w-6" /> Procesando...
                  </>
                ) : null
              }
            >
              {isLoading ? '' : 'Buscar'}
            </Button>
          </form>
        </div>
        <div className="md:col-span-3 p-4">
          {isLoading || isRefetching ? (
            <div className="w-full flex justify-center items-center text-center font-body mt-10">
              <Loader /> Buscando...
            </div>
          ) : null}

          {isSuccess && isArrayEmpty(data.results) ? (
            <div className="text-center font-body mt-10">No se encontraron resultados</div>
          ) : null}

          {isSuccess && !isArrayEmpty(data.results) && (
            <>
              <ul className="divide-y divide-gray-100">
                {data.results.map((ticket, index) => {
                  const parsedRequestedBy = TICKET_REQUESTERS[ticket.requested_by];
                  const parsedType = TICKET_TYPES[ticket.type];
                  const createdDate = moment.utc(ticket.created_at).local();
                  return (
                    <LiItem
                      id={ticket.id}
                      key={`ticket-${index}`}
                      firstText={`${parsedType.tag}`}
                      secondText={
                        <>
                          Requester: <strong>{parsedRequestedBy.tag}</strong>
                        </>
                      }
                      thirdText={
                        <>
                          Solicitado:{' '}
                          <strong>{createdDate.format('DD [de] MMMM [del] YYYY')}</strong>
                        </>
                      }
                      thirdTextIcon={<ClockIcon className="ml-0 h-5 w-5 text-gray-400 mr-1" />}
                      tag={`Acompañamiento #${ticket.accompaniment_id}`}
                      tagColor={'tertiary'}
                      withDot={false}
                      onClick={() => onClickTickets(ticket.id)}
                    />
                  );
                })}
              </ul>
            </>
          )}

          <nav
            className="mt-4 flex items-center justify-between border-t border-gray-200 bg-white py-3 "
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">1</span> to{' '}
                <span className="font-medium">10</span> of <span className="font-medium">20</span>{' '}
                results
              </p>
            </div>

            <div className="flex flex-1 justify-between sm:justify-end gap-4">
              <div className="w-30">
                <Button
                  disabled={data?.previous === null || isRefetching}
                  onClick={() => onClickPagination(false)}
                  className="rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Previous
                </Button>
              </div>
              <div className="w-30">
                <Button
                  disabled={data?.next === null || isRefetching}
                  onClick={() => onClickPagination(true)}
                  className="rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Next
                </Button>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </BasicLayout>
  );
}
