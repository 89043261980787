import { ChevronRightIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Select from 'components/Select';
import Subtitle from 'components/Subtitle';
import Tag from 'components/Tag';
import moment from 'moment';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AccompanimentUpdateSchema } from 'schemas/accompaniment';
import { getDetail as getSessionDetail, updateSession } from 'services/session';
import { getToken } from 'state/auth';
import { SESSION_STATUSES_MENU } from 'utils/menus/session';

import { getActionsByStatus } from './actions';
// import { sessionStatuses, sessionStatusesTagColor } from 'utils/parsers';
// import UserContent from './components/UserContent';

export default function SessionDetail() {
  // Global state
  const token = useRecoilValue(getToken);

  // PARAMS
  const { id } = useParams();

  // RRD
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  // RHF
  const {
    reset,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(AccompanimentUpdateSchema),
  });

  // RQ
  const getSessionDetailQuery = useQuery(
    ['sessionDetail', token, id],
    () => getSessionDetail(token, id),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnReconnect: false,
      // refetchOnMount: false,
      onSuccess: (data) => {
        reset({ status: data.status });
      },
    },
  );

  const updateSessionMutation = useMutation(
    (data) => {
      updateSession(token, id, data);
    },
    {
      onSuccess: async () => {
        await getSessionDetailQuery.refetch();
        toast.success('Se actualizó la información con exito.');
      },
      onError: () => toast.error('Algo salio mal.'),
    },
  );

  const onChangeSelect = (field, value) => {
    setValue(field, value);
  };

  const onSubmit = (values) => {
    updateSessionMutation.mutate(values);
  };

  const onClickSession = (id) => {
    navigate(`/ic/sessions/${id}`);
  };

  if (getSessionDetailQuery.isLoading) {
    return <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">Cargando...</div>;
  }
  if (getSessionDetailQuery.isError) {
    return <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">Error...</div>;
  }

  if (getSessionDetailQuery.isSuccess) {
    const session = getSessionDetailQuery.data;
    const { patient, psychologist } = session;
    // Dates
    const format = 'DD/MM/YYYY H:mm';
    const startDateUTC = moment(session.start_date);
    const endDateUTC = moment(session.end_date);
    const localTimezone = moment.tz.guess(true);
    const localCountryCode = moment.tz.zone(localTimezone).countries()[0];
    const patientStartDate = startDateUTC.tz(patient.timezone).format(format);
    const patientEndDate = endDateUTC.tz(patient.timezone).format(format);
    const psychologistStartDate = startDateUTC.tz(psychologist.timezone).format(format);
    const psychologistEndDate = endDateUTC.tz(psychologist.timezone).format(format);
    const guestStartDate = startDateUTC.tz(localTimezone).format(format);
    const guestEndDate = endDateUTC.tz(localTimezone).format(format);

    const sessionNumber = session.number;
    const sessionStatus = session.status;
    // const sessionStatusText = sessionStatuses[String(sessionStatus)];
    // const sessionStatusColor = sessionStatusesTagColor[String(sessionStatus)];
    const actions = Array.from(getActionsByStatus(sessionStatus, session.reviewed));

    const buildAction = (action, index) => {
      return (
        <button
          key={index}
          className="w-full flex justify-between items-center hover:bg-gray-50 shadow-sm rounded-md border border-2 border-tertiary py-2 px-2 my-2 cursor-pointer hover:shadow-md"
          onClick={() => navigate(action.navigate)}
        >
          <div className="flex-1 flex justify-start items-center">
            {action.icon}
            <p className="ml-4 font-body font-normal leading-5 text-md text-gray-500">
              {action.title}
            </p>
          </div>
          <ChevronRightIcon className="h-5 w-5 text-gray-400" />
        </button>
      );
    };

    const buildTask = (task, index) => {
      return (
        <li key={index} className="py-1 text-gray-500">
          {task.content}
        </li>
      );
    };

    return (
      <BasicLayout title={`Detalle de la sesión ${sessionNumber}`}>
        <Tag id={session.id} color={'primary'} tag={`#${session.id}`} />
        <div className="grid grid-cols-1 lg:grid-cols-2 items-baseline gap-4">
          <div className="grid grid-cols-1">
            {/* TIMEZONES */}
            <div className="lg:col-span-1 grid grid-cols-1 gap-4 mt-4">
              <div className="grid gap-1">
                <p className="mr-1 text-lg font-header text-gray-500 ">Dover:</p>
                <p className="mr-1 text-gray-600 font-bold">
                  {psychologist.full_name}
                  <ReactCountryFlag countryCode={psychologist.country_code} />
                </p>
              </div>
              <div className="grid gap-1">
                <p className="mr-1 text-lg font-header text-gray-500 ">Patient:</p>
                <p className="mr-1 text-gray-600 font-bold">
                  {patient.full_name}
                  <ReactCountryFlag countryCode={patient.country_code} />
                </p>
              </div>
              <div className="grid gap-1">
                <p className="mr-1 text-lg font-header text-gray-500 ">Guest:</p>
                <p className="mr-1 text-gray-600 font-bold">
                  Admin <ReactCountryFlag countryCode={localCountryCode} />
                </p>
              </div>
              <div className="grid gap-4">
                <p className="mr-1 text-lg font-header text-gray-500">Horarios Siguiente sesión:</p>
                <div className="grid grid-cols-1 gap-4">
                  <div className="flex items-center">
                    <p className="mr-2 text-gray-400 ">
                      {`${psychologistStartDate} - ${psychologistEndDate} (${psychologist.timezone})`}
                    </p>
                    <ReactCountryFlag countryCode={psychologist.country_code} />
                  </div>
                  <div className="flex items-center">
                    <p className="mr-2 text-gray-400 ">
                      {`${patientStartDate} - ${patientEndDate} (${patient.timezone})`}
                    </p>
                    <ReactCountryFlag countryCode={patient.country_code} />
                  </div>
                  <div className="flex items-center">
                    <p className="mr-2 text-gray-400 ">{`${guestStartDate} - ${guestEndDate} (${localTimezone})`}</p>
                    <ReactCountryFlag countryCode={localCountryCode} />
                  </div>
                </div>
              </div>
            </div>

            {/* UPDATE */}
            <div className="lg:col-span-1 grid grid-cols-1 pl-0 p-8 mt-8 lg:mt-0">
              <div className="col-span-1">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                  <Select
                    id="status"
                    textLabel="Estatus"
                    withIndex={false}
                    selectOptions={SESSION_STATUSES_MENU}
                    onChange={(e) => onChangeSelect('status', e.target.value)}
                    register={register}
                    registerConfig={{ required: true }}
                    error={errors.status?.message}
                  />

                  <Button
                    disabled={updateSessionMutation.isLoading}
                    type="submit"
                    className="btn-tertiary rounded-md"
                    icon={
                      updateSessionMutation.isLoading ? (
                        <>
                          <Loader className="text-white h-6 w-6" /> Editando...
                        </>
                      ) : null
                    }
                  >
                    {updateSessionMutation.isLoading ? '' : 'Editar'}
                  </Button>
                </form>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1  gap-4">
            <div className="col-span-2 md:col-span-1">
              {session.tasks?.length ? (
                <>
                  <Subtitle className="text-secondary">Tareas</Subtitle>
                  <ul className="list-inside list-decimal">{session.tasks.map(buildTask)}</ul>
                </>
              ) : null}

              {actions?.length ? (
                <>
                  <Subtitle className="text-gray-500">Acciones</Subtitle>
                  {actions.map(buildAction)}
                </>
              ) : null}
            </div>
            <div className="col-span-2">
              <Outlet context={{ session, refetchSession: getSessionDetailQuery.refetch }} />
            </div>
          </div>
        </div>
      </BasicLayout>
    );
  }

  return null;
}
