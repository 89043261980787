import {
  CalendarIcon,
  LocationMarkerIcon,
  OfficeBuildingIcon,
  TrashIcon,
  XIcon,
} from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import Divider from 'components/Divider';
import FormHeader from 'components/FormHeader';
import ExperienceForm from 'components/Forms/Psychologist/Experience';
import Modal from 'components/Modal';
import Title from 'components/Title';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { NewExperienceSchema } from 'schemas/psychologist';
import { createExperience, destroyExperience, listExperiences } from 'services/experiences';
import { getToken } from 'state/auth';

export default function PsychologistExperiences() {
  // Global State
  const token = useRecoilValue(getToken);

  // Local state
  const [modelOpen, setModalOpen] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);

  // PARAMS
  const { id } = useParams();

  // REACT QUERY
  const { data: experiences, refetch } = useQuery(['listPsychologistExperiences', token, id], () =>
    listExperiences(token, id),
  );

  const defaultExperience = {
    title: '',
    location: '',
    company: '',
    charge: '',
    start_date: null,
    end_date: null,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(NewExperienceSchema),
  });

  const buildExperience = (experience, index) => {
    return (
      <div key={index} className="px-6 py-8 my-2 border rounded-2xl shadow-sm hover:shadow-lg flex">
        <div className="flex-1">
          <Title className="leading-6 text-sm text-gray-500 mb-2">{`${experience.title} #${experience.id}`}</Title>
          <div className="w-full flex ">
            <OfficeBuildingIcon className="h-6 w-6 text-primary mr-2" />
            <p className="font-bold leadind-6 text-gray-500">
              {experience.charge} en {experience.company}
            </p>
          </div>
          <div className="w-full flex ">
            <LocationMarkerIcon className="h-6 w-6 text-primary mr-2" />
            <p className="font-bold leadind-6 text-gray-500">{experience.location}</p>
          </div>
          <div className="w-full flex items-center">
            <CalendarIcon className="h-6 w-6 text-primary mr-2" />
            <p className="leadind-6 text-gray-500">
              Desde <span className="font-bold ">{experience.start_date}</span> hasta{' '}
              <span className="font-bold ">
                {experience.end_date ? experience.end_date : 'Ahora'}
              </span>
            </p>
          </div>
        </div>
        <div>
          <button
            className="h-6 w-6"
            onClick={() => {
              setSelectedExperience(experience);
              setModalOpen(true);
            }}
          >
            <TrashIcon className="h-6 w-6 text-red-500 mr-2" />
          </button>
        </div>
      </div>
    );
  };

  const createExperienceMutation = useMutation(
    (body) => {
      createExperience(token, body);
    },
    {
      onSuccess: () => {
        refetch();
        reset();
        toast.success('Se guardo la experiencia con exito.');
      },
      onError: () => toast.error('Algo salio mal.'),
    },
  );

  const destroyExperiencesMutation = useMutation(
    () => {
      destroyExperience(token, selectedExperience?.id);
    },
    {
      onSuccess: () => {
        refetch();
        toast.success('Se eliminó la experiencia con éxito.');
      },
      onError: () => toast.error('Algo salio mal.'),
    },
  );

  const onSubmit = (data) => {
    const dateFormat = 'YYYY-MM-DD';
    const newExperience = { ...data };
    newExperience['psychologist'] = id;
    newExperience['start_date'] = moment(newExperience.start_date).format(dateFormat);
    newExperience['end_date'] = newExperience.end_date
      ? moment(newExperience.end_date).format(dateFormat)
      : null;

    createExperienceMutation.mutate(newExperience);
  };

  return (
    <div>
      <Modal
        type={'error'}
        title={`Eliminar experiencia "${selectedExperience?.title}"`}
        description={''}
        confirmButtonText={'Eliminar'}
        confirmButtonCallback={() => destroyExperiencesMutation.mutate()}
        cancelButtonText={'Cancelar'}
        cancelButtonCallback={() => {
          setTimeout(() => {
            setSelectedExperience(null);
          }, 300);
        }}
        Icon={XIcon}
        open={modelOpen}
        setOpen={setModalOpen}
        isLoading={false}
        isSuccess={false}
      />

      <Divider className="my-5" borderClassName="border-gray-200" aria-hidden="true" />

      <div className="flex flex-col md:flex-row box-border">
        <div className="flex-1 md:pr-4">
          <FormHeader title="Experiencias" />
          <div className="overflow-y-auto w-95">{experiences?.map(buildExperience)}</div>
        </div>
        <div className="flex-1 md:pr-4">
          {/* Experiences */}
          <form onSubmit={handleSubmit(onSubmit)} method="POST">
            <div className="my-6">
              <ExperienceForm
                register={register}
                errors={errors}
                defaultExperience={defaultExperience}
                disabled={createExperienceMutation.isLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
