import * as Yup from 'yup';

// TODO: add photo_URL
// Schema to retrieve and update patient
export const UpdatePatientSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  aboutMe: Yup.string(), // biography
  email: Yup.string().email('Correo electrónico invalido'),
  address: Yup.object().shape({
    country: Yup.number(),
    state: Yup.number(),
    timezone: Yup.number(),
    aka: Yup.string(),
    city: Yup.string(),
    street: Yup.string(),
    extNumber: Yup.string(),
    intNumber: Yup.string(),
    zipCode: Yup.string(),
    default: Yup.boolean(),
  }),
});

export const FilterPatientSchema = Yup.object().shape({
  search: Yup.string(),
  status: Yup.string(),
  isActive: Yup.bool(),
  isSuspended: Yup.bool(),
});
