import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

// const PRIVATE_PATH = 'private';
const COUPONS_PATH = 'coupon/';

export const listCoupons = async (token, filters, url) => {
  /**
   * search -> filters for pat by id or name
   */
  const { data } = await axios.get(url ? url : `${API_URL}${COUPONS_PATH}`, {
    headers: headers(token),
    params: url
      ? {}
      : {
          search: filters.search,
        },
  });

  return data;
};

export const createCoupon = async (token, body) => {
  const { data } = await axios.post(`${API_URL}${COUPONS_PATH}/`, body, {
    headers: headers(token),
  });

  return data.data;
};
