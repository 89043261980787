import { yupResolver } from '@hookform/resolvers/yup';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import Divider from 'components/Divider';
import FormHeader from 'components/FormHeader';
import Educations from 'components/Forms/Psychologist/Educations';
import Experiences from 'components/Forms/Psychologist/Experiences';
import Input from 'components/Input';
import Loader from 'components/Loader';
import ProfrilePictureInput from 'components/ProfilePictureInput';
import Select from 'components/Select';
import Tag from 'components/Tag';
import TextArea from 'components/TextArea';
import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { NewPsychologistSchema } from 'schemas/psychologist';
import { listCountries, listStates, listTimezones } from 'services/address';
import { createPsychologist } from 'services/auth';
import { getTags } from 'services/tags';
import { getToken } from 'state/auth';

export default function NewPsychologist() {
  // Global states
  const token = useRecoilValue(getToken);

  // Local states
  const [file, setFile] = useState(null);

  // RHF
  const defaultExperience = {
    title: '',
    location: '',
    company: '',
    charge: '',
    start_date: null,
    end_date: null,
  };
  const defaultEducation = {
    title: '',
    location: '',
    institute: '',
    start_date: null,
    end_date: null,
  };
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      experiences: [defaultExperience],
      educations: [defaultEducation],
    },
    resolver: yupResolver(NewPsychologistSchema),
  });

  const experiencesField = useFieldArray({
    control,
    name: 'experiences',
  });

  const educationsField = useFieldArray({
    control,
    name: 'educations',
  });

  // WATCHS
  const watchCountry = watch('address.country');
  const watchState = watch('address.state');
  const watchTags = watch('tags');

  // React Router
  const navigate = useNavigate();

  // React Query
  const listCountriesQuery = useQuery(['listCountries', token], () => listCountries(token), {
    refetchOnWindowFocus: false,
  });
  const listStatesQuery = useQuery(
    ['listStates', token, watchCountry],
    () => {
      const countryID = listCountriesQuery.data[`${watchCountry}`].id;
      return listStates(token, countryID);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!watchCountry,
    },
  );
  const listTimezonesQuery = useQuery(
    ['listTimezones', token, watchCountry],
    () => {
      const countryID = listCountriesQuery.data[`${watchCountry}`]?.id;
      return listTimezones(token, countryID);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!watchCountry,
    },
  );

  // TODO: REMOVE
  const listTagsQuery = useQuery(['getTags', token], () => getTags(token), {
    refetchOnWindowFocus: false,
  });

  // Mutation
  const createPsychologistMutation = useMutation(
    (data) => {
      createPsychologist(data, file);
    },
    {
      onSuccess: () => {
        toast.success('Se guardo la información con exito.');
        // navigate('/ic/psychologists');
      },
      onError: () => toast.error('Algo salio mal.'),
    },
  );

  const onSubmit = (data) => {
    // we verify we have tags
    if (!watchTags.length) {
      setError('tags', { message: 'Seleccione al menos un tag.' });
    } else {
      const {
        firstName,
        lastName,
        email,
        video,
        reason,
        biography,
        degree,
        address,
        address: { state, country, timezone },
        experiences,
        educations,
      } = data;

      const stateID = listStatesQuery.data[`${state}`].id;
      const countryID = listCountriesQuery.data[`${country}`].id;
      const timezoneID = listTimezonesQuery.data[`${timezone}`].id;
      const newAddress = Object.assign({}, address);
      newAddress['country'] = countryID;
      newAddress['timezone'] = timezoneID;
      newAddress['state'] = stateID;

      createPsychologistMutation.mutate({
        token,
        firstName,
        lastName,
        email,
        video,
        degree,
        reason,
        biography,
        tags: watchTags,
        experiences,
        educations,
        address: newAddress,
      });
    }
  };

  const handleTag = (id) => {
    let newSelectedTags = new Set(watchTags);
    if (newSelectedTags.has(id)) {
      newSelectedTags.delete(id);
    } else {
      newSelectedTags.add(id);
    }
    let st = Array.from(newSelectedTags);
    setValue('tags', st);
  };

  const buildTag = ({ id, text, color }) => {
    const _selectedTags = new Set(watchTags);
    const selected = _selectedTags.has(id);
    return (
      <div key={id} className="pl-2 pb-2">
        <Tag
          id={id}
          tag={text}
          selected={selected}
          color={color}
          onClickCallback={handleTag}
          withX={selected}
        />
      </div>
    );
  };

  const onChangeSelect = (field, value) => {
    setValue(field, value);
  };

  return (
    <section>
      {listCountriesQuery.isLoading && (
        <div className="text-center">
          <Loader /> Procesando ...
        </div>
      )}
      {listCountriesQuery.isSuccess && (
        <form onSubmit={handleSubmit(onSubmit)} method="POST">
          <BasicLayout
            title="Nuevo psicólogo"
            button={
              <Button
                disabled={createPsychologistMutation.isLoading}
                primary={true}
                className="border max-w-40% text-white font-medium rounded-md text-sm"
                type="submit"
              >
                Guardar
              </Button>
            }
          >
            <br />
            <div className="flex flex-col md:flex-row box-border">
              <div className="flex-1 md:pr-4">
                <FormHeader title="Información Personal" />
                <ProfrilePictureInput id="preview" setValue={setFile} />
                <div className="my-2">
                  <Input
                    id="firstName"
                    textLabel="Nombre"
                    type="text"
                    register={register}
                    registerConfig={{ required: true }}
                    withLabel
                    error={errors.firstName?.message}
                  />
                </div>
                <div className="my-2">
                  <Input
                    id="lastName"
                    textLabel="Apellido"
                    type="text"
                    register={register}
                    registerConfig={{ required: true }}
                    withLabel
                    error={errors.lastName?.message}
                  />
                </div>
                <div className="my-2">
                  <Input
                    id="email"
                    textLabel="Correo electrónico"
                    type="text"
                    register={register}
                    registerConfig={{ required: true }}
                    withLabel
                    error={errors.email?.message}
                  />
                </div>
                <div className="my-2">
                  <Input
                    id="video"
                    textLabel="Youtube Video"
                    type="text"
                    register={register}
                    registerConfig={{ required: true }}
                    withLabel
                    error={errors.video?.message}
                  />
                </div>
                <div className="my-2">
                  <Select
                    id="degree"
                    textLabel="Grado"
                    selectOptions={[
                      { id: 0, name: 'Becario' },
                      { id: 1, name: 'Dover' },
                      { id: 2, name: 'SuperDover' },
                      { id: 3, name: 'UltraDover' },
                    ]}
                    register={register}
                    registerConfig={{ required: true }}
                    withDefaultOption
                  />
                </div>
                <div className="my-2">
                  <TextArea
                    id="biography"
                    register={register}
                    textLabel="Biografía"
                    registerConfig={{ required: true }}
                    error={errors.biography?.message}
                    labelClassName="text-sm leading-5 text-gray-700"
                    rows="4"
                  />
                </div>
                <div className="my-2">
                  <TextArea
                    id="reason"
                    register={register}
                    textLabel="¿Por qué estoy en Mindove?"
                    registerConfig={{ required: true }}
                    error={errors.reason?.message}
                    labelClassName="text-sm leading-5 text-gray-700"
                    rows="4"
                  />
                </div>
              </div>
              <div className="flex-1 md:pl-4">
                {/* Address */}
                <FormHeader title="Ubicación" />
                <div className="my-2">
                  <Select
                    id="address.country"
                    textLabel="País"
                    selectOptions={listCountriesQuery.data}
                    register={register}
                    registerConfig={{ required: true }}
                    onChange={(e) => onChangeSelect('address.country', e.target.value)}
                    error={errors.address?.country?.message}
                    withDefaultOption
                  />
                </div>
                <div className="my-2">
                  <Select
                    id="address.state"
                    textLabel="Estados"
                    disabled={!watchCountry || listStatesQuery.isLoading}
                    selectOptions={!listStatesQuery.data ? [] : listStatesQuery.data}
                    register={register}
                    registerConfig={{ required: true }}
                    onChange={(e) => onChangeSelect('address.state', e.target.value)}
                    error={errors.address?.state?.message}
                    withDefaultOption
                  />
                </div>
                <div className="my-2">
                  <Select
                    disabled={!watchCountry && !watchState}
                    id="address.timezone"
                    textLabel="Zona horaria"
                    selectOptions={!listTimezonesQuery.data ? [] : listTimezonesQuery.data}
                    register={register}
                    registerConfig={{ required: true }}
                    onChange={(e) => onChangeSelect('address.timezone', e.target.value)}
                    error={errors.address?.timezone?.message}
                    withDefaultOption
                  />
                </div>
                <div className="my-2">
                  <Input
                    id="address.city"
                    textLabel="Ciudad"
                    type="text"
                    register={register}
                    registerConfig={{ required: true }}
                    withLabel
                    error={errors.address?.city?.message}
                  />
                </div>
                <div className="my-2">
                  <Input
                    id="address.street"
                    textLabel="Calle"
                    type="text"
                    register={register}
                    registerConfig={{ required: true }}
                    withLabel
                    error={errors.address?.street?.message}
                  />
                </div>
                <div className="my-2">
                  <Input
                    id="address.extNumber"
                    textLabel="Número exterior"
                    type="text"
                    register={register}
                    registerConfig={{ required: true }}
                    withLabel
                    error={errors.address?.extNumber?.message}
                  />
                </div>
                <div className="my-2">
                  <Input
                    id="address.intNumber"
                    textLabel="Número interior"
                    type="text"
                    register={register}
                    registerConfig={{ required: true }}
                    withLabel
                    error={errors.address?.intNumber?.message}
                  />
                </div>
                <div className="my-2">
                  <Input
                    id="address.zipCode"
                    textLabel="Código postal"
                    type="text"
                    register={register}
                    registerConfig={{ required: true }}
                    withLabel
                    error={errors.address?.zipCode?.message}
                  />
                </div>
              </div>
            </div>

            <Divider className="my-5" borderClassName="border-gray-200" aria-hidden="true" />
            <div className="flex flex-col md:flex-row box-border">
              <div className="flex-1">
                <FormHeader title="Tags" />
                <div className="pl-0 md:pr-8">
                  {listTagsQuery.isLoading ? null : (
                    <>
                      <div>
                        <p className="text-sm leading-5 font-body font-normal text-gray-500 mt-4">
                          <span className="font-bold">
                            ¿El Cather tiene experiencia con alguna de las siguientes ocupaciones?
                          </span>
                        </p>
                      </div>
                      <div className="mt-4 flex flex-wrap">
                        {listTagsQuery.data.data?.occupation_tags.map(buildTag)}
                      </div>
                      <div>
                        <p className="text-sm leading-5 font-body font-normal text-gray-500 mt-4">
                          <span className="font-bold">
                            ¿En que problematicas se especializa el Cather?
                          </span>
                        </p>
                      </div>
                      <div className="mt-4 flex flex-wrap">
                        {listTagsQuery.data.data?.condition_tags.map(buildTag)}
                      </div>
                      <div>
                        {errors.tags && (
                          <p className="text-sm font-body font-medium text-red-500 pt-2">
                            {errors.tags?.message}
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <Divider className="my-5" borderClassName="border-gray-200" aria-hidden="true" />
            <div className="flex flex-col md:flex-row box-border">
              <div className="flex-1 md:pr-4">
                {/* Experiences */}
                <Experiences
                  fieldArray={experiencesField}
                  register={register}
                  errors={errors.experiences}
                  defaultExperience={defaultExperience}
                />
              </div>
              <div className="flex-1 md:pl-4">
                <Educations
                  fieldArray={educationsField}
                  register={register}
                  errors={errors.educations}
                  defaultExperience={defaultEducation}
                />
              </div>
            </div>
          </BasicLayout>
        </form>
      )}
    </section>
  );
}
