import axios from 'axios';
import Compressor from 'compressorjs';
import { API_URL } from 'utils/constants';

const GENERICS_PATH = 'generics/';

export const uploadPhoto = async (token, file) => {
  const wait = async () => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 500,
        resize: 'cover',
        success(result) {
          const formData = new FormData();
          formData.append('photo', result, result.name);
          axios
            .post(`${API_URL}${GENERICS_PATH}upload-photo/`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
              },
            })
            .then((data) => {
              resolve(data.data);
            });
        },
        error(err) {
          reject(err.message);
        },
      });
    });
  };

  return await wait();
};
