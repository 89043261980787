import Button from 'components/Button';
import Divider from 'components/Divider';
import PsychologistProfile from 'components/Forms/Psychologist/Profile';
import ProfrilePictureInput from 'components/ProfilePictureInput';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useOutletContext, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { updatePsychologistPhoto } from 'services/psychologists';
import { getToken } from 'state/auth';

import PsychologistEducations from './Educations';
import PsychologistExperiences from './Experiences';

export default function Profile() {
  // Global State
  const token = useRecoilValue(getToken);

  // Outlet context
  const data = useOutletContext();

  // Local states
  const [file, setFile] = useState(null);

  // PARAMS
  const { id } = useParams();

  // Local states
  const [classDisabled, setClassDisabled] = useState(false);

  // React query
  const updatePhotonMutation = useMutation(
    () => {
      updatePsychologistPhoto(token, id, file);
    },
    {
      onSuccess: () => {
        toast.success('Se guardo la foto con exito.');
      },
      onError: () => toast.error('Algo salio mal.'),
    },
  );

  const onSubmitPhoto = () => {
    if (file) {
      updatePhotonMutation.mutate();
    }
  };

  return (
    <div>
      <br />
      <ProfrilePictureInput id="preview" prevImgSrc={data?.photo_URL} setValue={setFile} />
      {file && (
        <>
          <Button className="btn-secondary rounded-md  my-4" onClick={onSubmitPhoto}>
            Guardar
          </Button>
        </>
      )}
      <Divider className="my-5" borderClassName="border-gray-200" aria-hidden="true" />
      <PsychologistProfile
        data={data}
        classDisabled={classDisabled}
        setClassDisabled={setClassDisabled}
      />
      <PsychologistExperiences />
      <PsychologistEducations />
    </div>
  );
}
