import { SaveIcon, TrashIcon, UserAddIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import FormHeader from 'components/FormHeader';
import Input from 'components/Input';
import Loader from 'components/Loader';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AssociateCompanyPatientsSchema } from 'schemas/company';
import { associateCompanyPatients } from 'services/companies';
import { getToken } from 'state/auth';
import { showErrorToast, showSuccessToast } from 'utils/toast';

export default function CompanyAssociatePatients() {
  // GLOBAL STATES
  const token = useRecoilValue(getToken);

  // PARAMS
  const { id: companyID } = useParams();

  // RRD
  const navigate = useNavigate();

  // RHF
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      patients: [
        {
          email: '',
        },
      ],
    },
    resolver: yupResolver(AssociateCompanyPatientsSchema),
  });

  console.log('🚀 ~ CompanyAssociatePatients ~ errors:', errors);
  const patientsFieldArray = useFieldArray({
    control,
    name: 'patients',
  });

  // React Query
  const associateCompanyPatientsMutation = useMutation(
    (data) => associateCompanyPatients(token, data),
    {
      onSuccess: () => {
        showSuccessToast('¡Éxito! Los pacientes fueron asociados correctamente.');
        reset();
      },
      onError: () => {
        showErrorToast('¡Error! Algo salió mal.');
      },
    },
  );

  const onSubmit = (data) => {
    const emails = data.patients.map((patient) => patient.email);
    const body = {
      company_id: companyID,
      emails,
    };
    associateCompanyPatientsMutation.mutate(body);
  };

  return (
    <BasicLayout title={`Agregar Pacientes - Company #${companyID}`}>
      <form onSubmit={handleSubmit(onSubmit)} method="PUT" className="space-y-4">
        <FormHeader title="Pacientes" />
        <p className="text-xs text-gray-400">
          Debe ingresar el <strong>email</strong> del paciente que desea agregar a la compañia.
        </p>

        <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-4">
          {patientsFieldArray.fields.map((field, index) => {
            return (
              <div key={`patients-${index}`} className="col-span-1 grid grid-cols-6 gap-4">
                <div className="col-span-5">
                  <Input
                    id={`patients.${index}.email`}
                    textLabel={`Patient Email #${index + 1}`}
                    type="text"
                    register={register}
                    registerConfig={{}}
                    withLabel
                    error={errors?.patients?.[String(index)]?.email?.message}
                  />
                </div>
                {index > 0 && (
                  <div className="flex justify-center items-start w-10">
                    <Button
                      className="bg-red-400 border text-white font-medium rounded-md text-sm p-2 flex justify-center items-center"
                      onClick={() => patientsFieldArray.remove(index)}
                    >
                      <TrashIcon className="h-4 w-4 text-white" />
                    </Button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="w-full lg:flex lg:justify-start gap-4">
          <Button
            disabled={associateCompanyPatientsMutation.isLoading}
            className="btn-primary rounded-md w-full lg:w-12 mb-4 lg:mb-0"
            onClick={() =>
              patientsFieldArray.append({
                email: '',
              })
            }
            icon={<UserAddIcon className="text-white h-6 w-6" />}
          ></Button>
          <Button
            disabled={associateCompanyPatientsMutation.isLoading}
            type="submit"
            className="btn-tertiary rounded-md w-full lg:w-28"
            icon={<SaveIcon className="text-white h-6 w-6 mr-2" />}
          >
            Guardar
          </Button>
          {associateCompanyPatientsMutation.isLoading ? (
            <div className="flex items-center">
              <Loader className="text-primary h-6 w-6" />
            </div>
          ) : null}
        </div>

        {associateCompanyPatientsMutation.isError ? (
          <p className="text-xs text-red-400">
            {associateCompanyPatientsMutation.error.response.data.emails?.[0]}
          </p>
        ) : null}
      </form>
    </BasicLayout>
  );
}
