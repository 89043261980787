import { PlusIcon, UserIcon } from '@heroicons/react/solid';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import ContactCard from 'components/ContactCard';
import Loader from 'components/Loader';
import TabContainer from 'components/TabContainer';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getAllHops } from 'services/hops';
import { getToken } from 'state/auth';
import { isArrayEmpty } from 'utils/base';

const tabs = [
  {
    name: 'Activos',
    icon: UserIcon,
    filters: { isActive: true },
  },
];

export default function AllPsychologists() {
  // GLOBAL STATES
  const token = useRecoilValue(getToken);

  // LOCAL STATES
  const [activeTab, setActiveTab] = useState(0);

  // REACT QUERY
  const { data, isSuccess, isLoading } = useQuery(['listHops', token], () => getAllHops(token));

  // ROUTER
  const navigate = useNavigate();

  // TODO: manage errors

  return (
    <BasicLayout
      title="Jefes de psicólogos"
      button={
        <Button
          primary={true}
          icon={<PlusIcon className="h-5 w-5 text-white mr-2" />}
          className="border max-w-48% text-white font-medium rounded-md text-sm"
          onClick={() => navigate('new')}
        >
          Agregar nuevo jefe de psicólogos
        </Button>
      }
    >
      <TabContainer tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />

      <div>
        {isLoading ? (
          <div className="w-full flex justify-center items-center text-center font-body mt-10">
            <Loader /> Buscando...
          </div>
        ) : (
          isSuccess &&
          isArrayEmpty(data) && (
            <div className="w-full flex justify-center items-center text-center font-body mt-10">
              Ningún resultado
            </div>
          )
        )}
        {isSuccess && !isArrayEmpty(data) && (
          <div className="flex flex-col md:grid grid-cols-2 auto-rows-fr gap-x-3 box-border">
            {data.map((psychologist, index) => {
              return (
                <div key={index} className="my-4 border rounded-lg shadow-md">
                  <ContactCard title={psychologist.first_name + ' ' + psychologist.last_name}>
                    <img
                      className="rounded-full h-8 w-8 lg:w-10 lg:h-10"
                      src={psychologist.photo_URL}
                      alt=""
                    />
                  </ContactCard>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </BasicLayout>
  );
}
