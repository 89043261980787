import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

// const PRIVATE_PATH = 'private';
const COMPANIES_PATH = 'company/';

export const listCompanies = async (token, filters, url) => {
  /**
   * search -> filters for pat by id or name
   */
  const { data } = await axios.get(url ? url : `${API_URL}${COMPANIES_PATH}`, {
    headers: headers(token),
    params: url
      ? {}
      : {
          search: filters.search,
        },
  });

  return data;
};

export const createCompany = async (token, body) => {
  const { data } = await axios.post(`${API_URL}${COMPANIES_PATH}`, body, {
    headers: headers(token),
  });

  return data;
};

export const getCompany = async (token, id) => {
  const { data } = await axios.get(`${API_URL}${COMPANIES_PATH}${id}`, {
    headers: headers(token),
  });

  return data;
};

export const listCompanyPatients = async (token, id) => {
  const { data } = await axios.get(`${API_URL}${COMPANIES_PATH}${id}/patients/`, {
    headers: headers(token),
  });

  return data;
};

export const associateCompanyPatients = async (token, body) => {
  const { data } = await axios.put(`${API_URL}${COMPANIES_PATH}associate/`, body, {
    headers: headers(token),
  });

  return data;
};
