/* eslint-disable no-unused-vars */
import { Switch } from '@headlessui/react';
import { PencilIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Button';
import CustomSelect from 'components/CustomSelect';
import Divider from 'components/Divider';
import FormHeader from 'components/FormHeader';
import Input from 'components/Input';
import Loader from 'components/Loader';
import Select from 'components/Select';
import TextArea from 'components/TextArea';
import { useUserCountriesState } from 'hooks/useUserCountriesState';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { UpdatePsychologistSchema } from 'schemas/psychologist';
import { listCountries, listStates, listTimezones } from 'services/address';
import { updatePsychologist } from 'services/psychologists';
import { getToken } from 'state/auth';
import { userOptionsCustomSelect } from 'utils/cssConstants';

import Experiences from './Experiences';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
export default function Profile({ data, classDisabled, setClassDisabled }) {
  // HOOKS
  const { onChangeCountrySelect } = useUserCountriesState();

  // GLOBAL STATES
  const token = useRecoilValue(getToken);

  // LOCAL STATES
  const [isActive, setIsActive] = useState(data.is_active);

  // PARAMS
  const { id } = useParams();

  // RHF

  // const defaultEducation = {
  //   title: '',
  //   location: '',
  //   institute: '',
  //   start_date: null,
  //   end_date: null,
  // };
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: data.first_name,
      lastName: data.last_name,
      degree: data.degree,
      video: data.video,
      email: data.email,
      reason: data.reason,
      aboutMe: data.biography,
      isActive: data.is_active,
      bankAccount: data.bank_account,
      // experiences: data.experiences,
      // address: {
      //   default: data.address.default,
      //   country: data.address.country,
      //   state: data.address.state,
      //   aka: data.address.aka,
      //   city: data.address.city,
      //   street: data.address.street,
      //   extNumber: data.address.ext_number,
      //   intNumber: data.address.int_number,
      //   zipCode: data.address.zip_code,
      // },
    },
    resolver: yupResolver(UpdatePsychologistSchema),
  });

  const experiencesField = useFieldArray({
    control,
    name: 'experiences',
  });

  // WATCHS
  const watchCountry = watch('address.country');
  const watchState = watch('address.state');
  const watchIsActive = watch('isActive');

  // React query
  // const listCountriesQuery = useQuery(['listCountries', token], () => listCountries(token), {
  //   refetchOnWindowFocus: false,
  // });

  // const listStatesQuery = useQuery(
  //   ['listStates', token, watchCountry],
  //   () => listStates(token, watchCountry),
  //   {
  //     refetchOnWindowFocus: false,
  //     enabled: !!watchCountry,
  //   },
  // );

  // const listTimezonesQuery = useQuery(
  //   ['listTimezones', token, watchCountry],
  //   () => listTimezones(token, watchCountry),
  //   {
  //     refetchOnWindowFocus: false,
  //     enabled: !!watchCountry,
  //   },
  // );

  const updatePsychologistMutation = useMutation(
    (data) => {
      updatePsychologist(
        data.token,
        data.id,
        data.firstName,
        data.lastName,
        data.email,
        data.video,
        data.degree,
        data.reason,
        data.aboutMe,
        data.isActive,
        data.bankAccount,
        // data.address,
        // data.experiences,
      );
    },
    {
      onSuccess: () => toast.success('Se guardo la información con exito.'),
      onError: () => toast.error('Algo salio mal.'),
    },
  );

  // HANDLERS
  const onSubmit = (psychologistData) => {
    updatePsychologistMutation.mutate({
      token,
      id,
      ...psychologistData,
      // experiences,
    });
  };

  const onSelectHandler = (event, data, name, callback) => {
    if (callback) {
      callback(event, data);
    }
    setValue(name, event.target.value);
  };

  // CONSTANTS
  // ? -- defaultValue --
  // ? defaultValue const is to get is active or inactive options
  // ? with their value, text and icon for the custom select.
  const defaultValue = userOptionsCustomSelect.filter((option) => option.id == isActive);

  return (
    <div>
      {/* {listCountriesQuery.isLoading ||
        listTimezonesQuery.isSuccess ||
        (listStatesQuery.isSuccess && (
          <div className="text-center">
            <Loader /> Procesando ...
          </div>
        ))} */}

      <form onSubmit={handleSubmit(onSubmit)} method="POST">
        <div>
          <div className="my-6">
            <div className="flex flex-col md:flex-row box-border">
              <div className="flex-1 md:pr-4">
                <FormHeader title="Estatus" />
                <div className="my-4 w-full">
                  <Switch.Group as="div" className="flex items-center justify-between">
                    <span className="flex-grow flex flex-col">
                      <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                        Estatus del paciente
                      </Switch.Label>
                      <Switch.Description as="span" className="text-sm text-gray-500">
                        {watchIsActive ? 'Activo' : 'Inactivo'}
                      </Switch.Description>
                    </span>
                    <Switch
                      disabled={updatePsychologistMutation.isLoading}
                      checked={watchIsActive}
                      onChange={(value) => setValue('isActive', value)}
                      className={classNames(
                        watchIsActive ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          watchIsActive ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
              </div>
            </div>
            <Divider className="my-5" borderClassName="border-gray-200" aria-hidden="true" />
            <div className="flex flex-col md:flex-row box-border">
              <div className="flex-1 md:pr-4">
                <FormHeader title="Información básica" />
                <div className="col-span-6 sm:col-span-3">
                  <div className="flex flex-col md:flex-row my-2">
                    <div className="flex-1 md:mr-2">
                      <Input
                        id="firstName"
                        textLabel="Nombre"
                        type="text"
                        register={register}
                        registerConfig={{ required: true }}
                        withLabel
                        disabled={classDisabled}
                        error={errors.firstName?.message}
                      />
                    </div>
                    <div className="flex-1 md:mr-2">
                      <Input
                        id="lastName"
                        textLabel="Apellido"
                        type="text"
                        register={register}
                        registerConfig={{ required: true }}
                        withLabel
                        disabled={classDisabled}
                        error={errors.lastName?.message}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row my-2">
                    <div className="flex-1 md:mr-2">
                      <Input
                        id="email"
                        textLabel="Correo electrónico"
                        type="text"
                        register={register}
                        registerConfig={{ required: true }}
                        withLabel
                        disabled={classDisabled}
                        error={errors.email?.message}
                      />
                    </div>
                    <div className="flex-1 md:mr-2">
                      <Select
                        id="degree"
                        textLabel="Tipo"
                        withIndex={false}
                        selectOptions={[
                          { id: 0, name: 'Becario' },
                          { id: 1, name: 'Dover' },
                          { id: 2, name: 'SuperDover' },
                          { id: 3, name: 'UltraDover' },
                        ]}
                        onChange={(e) => onSelectHandler(e, [], 'degree')}
                        register={register}
                        registerConfig={{ required: true }}
                        error={errors.degree?.message}
                        disabled={classDisabled}
                      />
                    </div>
                  </div>
                  <div className="my-2">
                    <Input
                      id="video"
                      textLabel="Video URL"
                      type="url"
                      register={register}
                      registerConfig={{ required: true }}
                      withLabel
                      disabled={classDisabled}
                      error={errors.video?.message}
                    />
                  </div>
                  <div className="my-2">
                    <TextArea
                      id="aboutMe"
                      description="aboutMe"
                      register={register}
                      registerConfig={{ required: true }}
                      textLabel="Acerca de mí"
                      labelClassName="text-sm leading-5"
                      error={errors.aboutMe?.message}
                      rows="4"
                      disabled={classDisabled}
                    />
                  </div>
                  {/* Address */}
                  {/* <Divider
                      className="my-5"
                      borderClassName="border-gray-200"
                      aria-hidden="true"
                    />
                    <FormHeader title="Ubicación" />
                    <div className="flex flex-col md:flex-row my-2">
                      <div className="flex-1 md:mr-2">
                        <Select
                          id="address.country"
                          textLabel="País"
                          selectOptions={listCountriesQuery.data}
                          withIndex={false}
                          register={register}
                          onChange={(e) =>
                            onSelectHandler(
                              e,
                              listCountriesQuery.data,
                              'address.country',
                              onChangeCountrySelect,
                            )
                          }
                          registerConfig={{ required: true }}
                          disabled={classDisabled}
                          error={errors.address?.country?.message}
                        />
                      </div>

                      <div className="flex-1 md:mr-2">
                        <Select
                          id="address.state"
                          textLabel="Estado"
                          withIndex={false}
                          selectOptions={!listStatesQuery.data ? [] : listStatesQuery.data}
                          register={register}
                          onChange={(e) =>
                            onSelectHandler(e, listCountriesQuery.data, 'address.state')
                          }
                          registerConfig={{ required: true }}
                          disabled={classDisabled || !watchCountry || listStatesQuery.isLoading}
                          withDefaultOption
                          error={errors.address?.state?.message}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row my-2">
                      <div className="flex-1 md:mr-2">
                        <Select
                          id="address.timezone"
                          textLabel="Zona horaria"
                          selectOptions={!listTimezonesQuery.data ? [] : listTimezonesQuery.data}
                          withIndex={false}
                          register={register}
                          onChange={(e) =>
                            onSelectHandler(e, listCountriesQuery.data, 'address.timezone')
                          }
                          registerConfig={{ required: true }}
                          error={errors.address?.timezone?.message}
                          disabled={classDisabled || (!watchCountry && !watchState)}
                          withDefaultOption
                        />
                      </div>

                      <div className="flex-1 md:mr-2">
                        <Input
                          id="address.city"
                          textLabel="Ciudad"
                          type="text"
                          register={register}
                          registerConfig={{ required: true }}
                          withLabel
                          error={errors.address?.city?.message}
                          disabled={classDisabled}
                        />
                      </div>
                    </div>
                    <div className="my-2">
                      <Input
                        id="address.street"
                        textLabel="Calle"
                        type="text"
                        register={register}
                        registerConfig={{ required: true }}
                        withLabel
                        error={errors.address?.street?.message}
                        disabled={classDisabled}
                      />
                    </div>
                    <div className="flex flex-col md:flex-row my-2">
                      <div className="flex-1 md:mr-2">
                        <Input
                          id="address.extNumber"
                          textLabel="Número exterior"
                          type="text"
                          register={register}
                          registerConfig={{ required: true }}
                          withLabel
                          error={errors.address?.extNumber?.message}
                          disabled={classDisabled}
                        />
                      </div>

                      <div className="flex-1 md:mr-2">
                        <Input
                          id="address.intNumber"
                          textLabel="Número interior"
                          type="text"
                          register={register}
                          registerConfig={{ required: true }}
                          error={errors.address?.intNumber?.message}
                          withLabel
                          disabled={classDisabled}
                        />
                      </div>
                    </div>
                    <div className="my-2">
                      <Input
                        id="address.zipCode"
                        textLabel="Código postal"
                        type="text"
                        register={register}
                        registerConfig={{ required: true }}
                        withLabel
                        error={errors.address?.zipCode?.message}
                        disabled={classDisabled}
                      />
                    </div> */}
                </div>
              </div>
              <div className="flex-1 md:pr-4">
                <div className="my-2">
                  <TextArea
                    id="reason"
                    description="reason"
                    register={register}
                    registerConfig={{ required: true }}
                    error={errors.reason?.message}
                    textLabel="¿Por qué estoy en Mindove?"
                    labelClassName="text-sm leading-5 text-gray-700"
                    rows="4"
                    disabled={classDisabled}
                  />
                </div>
                <div className="my-2">
                  <Input
                    id="bankAccount"
                    textLabel="Cuenta bancaria"
                    type="text"
                    register={register}
                    registerConfig={{ required: true }}
                    withLabel
                    disabled={classDisabled}
                    error={errors.bankAccount?.message}
                  />
                </div>
                <br />
                <Button
                  disabled={updatePsychologistMutation.isLoading}
                  primary
                  type="submit"
                  icon={<PencilIcon className="h-5 w-5 text-white mr-2" />}
                  className="border bg-secondary  font-medium rounded-md text-sm"
                >
                  Editar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

Profile.propTypes = {
  data: PropTypes.object,
  handleSubmit: PropTypes.func,
  classDisabled: PropTypes.bool,
  setClassDisabled: PropTypes.func,
};
