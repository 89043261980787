import axios from 'axios';
import moment from 'moment';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

import { uploadPhoto } from './generics';

const AUTH_PATH = 'auth/';

export const getTemporaryCode = async (email, role) => {
  const { data } = await axios.post(`${API_URL}${AUTH_PATH}login/${role}/`, {
    email,
  });

  return data;
};

export const getToken = async (code, email) => {
  const { data } = await axios.post(`${API_URL}${AUTH_PATH}token/`, {
    code,
    email,
  });

  return data.data;
};

export const createPsychologist = async (dataPsychologist, file = null) => {
  const {
    firstName,
    token,
    lastName,
    email,
    video,
    degree,
    tags,
    biography,
    reason,
    address,
    experiences,
    educations,
  } = dataPsychologist;

  let uploadResponse;

  if (file) {
    uploadResponse = await uploadPhoto(token, file);
    dataPsychologist['photoURL'] = uploadResponse.url || '';
  }

  const dateFormat = 'YYYY-MM-DD';
  const newExperiences = experiences.map((experience) => {
    const newExperience = { ...experience };
    newExperience['start_date'] = moment(newExperience.start_date).format(dateFormat);
    newExperience['end_date'] = newExperience.end_date
      ? moment(newExperience.end_date).format(dateFormat)
      : null;
    return newExperience;
  });

  const newEducations = educations.map((education) => {
    const newEducation = { ...education };
    newEducation['start_date'] = moment(newEducation.start_date).format(dateFormat);
    newEducation['end_date'] = newEducation.end_date
      ? moment(newEducation.end_date).format(dateFormat)
      : null;
    return newEducation;
  });

  const { data } = await axios.post(
    `${API_URL}${AUTH_PATH}signup/psy/`,
    {
      first_name: firstName,
      last_name: lastName,
      email,
      video,
      degree,
      tags,
      biography,
      reason,
      photo_URL: dataPsychologist['photoURL'],
      address: {
        aka: address.aka,
        street: address.street,
        ext_number: address.extNumber,
        int_number: address.intNumber,
        city: address.city,
        zip_code: address.zipCode,
        default: address.default,
        state: address.state,
        country: address.country,
        timezone: address.timezone,
      },
      experiences: newExperiences,
      educations: newEducations,
    },
    {
      headers: headers(token),
    },
  );

  return data.data;
};
