import toast from 'react-hot-toast';

export const showSuccessToast = (text) => {
  toast.success(text, {
    style: {
      border: '2px solid #28a745', // Verde para éxito
      padding: '16px',
      backgroundColor: '#28a745', // Verde sólido
      color: '#fff', // Texto blanco
      borderRadius: '8px', // Bordes redondeados
      fontSize: '16px', // Texto un poco más grande
    },
    iconTheme: {
      primary: '#fff', // Blanco para el ícono
      secondary: '#28a745', // Fondo verde sólido
    },
  });
};

export const showErrorToast = (text) => {
  toast.error(text, {
    style: {
      border: '2px solid #dc3545', // Rojo para error
      padding: '16px',
      backgroundColor: '#dc3545', // Rojo sólido
      color: '#fff', // Texto blanco
      borderRadius: '8px', // Bordes redondeados
      fontSize: '16px', // Texto un poco más grande
    },
    iconTheme: {
      primary: '#fff', // Blanco para el ícono
      secondary: '#dc3545', // Fondo rojo sólido
    },
  });
};
