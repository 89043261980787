import {
  AcademicCapIcon,
  CalendarIcon,
  LocationMarkerIcon,
  TrashIcon,
  XIcon,
} from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import Divider from 'components/Divider';
import FormHeader from 'components/FormHeader';
import EducationForm from 'components/Forms/Psychologist/Education';
import Modal from 'components/Modal';
import Title from 'components/Title';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { NewEducationSchema } from 'schemas/psychologist';
import { createEducation, destroyEducation, listEducations } from 'services/experiences';
import { getToken } from 'state/auth';

export default function PsychologistEducations() {
  // Global State
  const token = useRecoilValue(getToken);

  // Local state
  const [modelOpen, setModalOpen] = useState(false);
  const [selectedEducation, setSelectedEducation] = useState(null);

  // PARAMS
  const { id } = useParams();

  // REACT QUERY
  const { data: educations, refetch } = useQuery(
    ['listPsychologistEducations', token, id],
    () => listEducations(token, id),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  const defaultEducation = {
    title: '',
    location: '',
    company: '',
    charge: '',
    start_date: null,
    end_date: null,
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(NewEducationSchema),
  });

  const createEducationMutation = useMutation(
    (body) => {
      createEducation(token, body);
    },
    {
      onSuccess: () => {
        refetch();
        reset();
        toast.success('Se guardo la experiencia con exito.');
      },
      onError: () => toast.error('Algo salio mal.'),
    },
  );

  const buildEducation = (education, index) => {
    return (
      <div key={index} className="px-6 py-8 my-2 border rounded-2xl shadow-sm hover:shadow-lg flex">
        <div className="flex-1">
          <Title className="leading-6 text-sm text-gray-500 mb-2">{`${education.title} #${education.id}`}</Title>
          <div className="w-full flex ">
            <AcademicCapIcon className="h-6 w-6 text-primary mr-2" />
            <p className="font-bold leadind-6 text-gray-500">{education.institute}</p>
          </div>
          <div className="w-full flex ">
            <LocationMarkerIcon className="h-6 w-6 text-primary mr-2" />
            <p className="font-bold leadind-6 text-gray-500">{education.location}</p>
          </div>
          <div className="w-full flex items-center">
            <CalendarIcon className="h-6 w-6 text-primary mr-2" />
            <p className="leadind-6 text-gray-500">
              Desde <span className="font-bold ">{education.start_date}</span> hasta{' '}
              <span className="font-bold ">
                {education.end_date ? education.end_date : 'Ahora'}
              </span>
            </p>
          </div>
        </div>

        <div>
          <button
            className="h-6 w-6"
            onClick={() => {
              setSelectedEducation(education);
              setModalOpen(true);
            }}
          >
            <TrashIcon className="h-6 w-6 text-red-500 mr-2" />
          </button>
        </div>
      </div>
    );
  };

  const destroyEducationMutation = useMutation(
    () => {
      destroyEducation(token, selectedEducation?.id);
    },
    {
      onSuccess: () => {
        refetch();
        toast.success('Se eliminó la experiencia con éxito.');
      },
      onError: () => toast.error('Algo salio mal.'),
    },
  );

  const onSubmit = (data) => {
    const dateFormat = 'YYYY-MM-DD';
    const newEducation = { ...data };
    newEducation['psychologist'] = id;
    newEducation['start_date'] = moment(newEducation.start_date).format(dateFormat);
    newEducation['end_date'] = newEducation.end_date
      ? moment(newEducation.end_date).format(dateFormat)
      : null;

    createEducationMutation.mutate(newEducation);
  };
  return (
    <div>
      <Modal
        type={'error'}
        title={`Eliminar educación "${selectedEducation?.title}"`}
        description={''}
        confirmButtonText={'Eliminar'}
        confirmButtonCallback={() => destroyEducationMutation.mutate()}
        cancelButtonText={'Cancelar'}
        cancelButtonCallback={() => {
          setTimeout(() => {
            setSelectedEducation(null);
          }, 300);
        }}
        Icon={XIcon}
        open={modelOpen}
        setOpen={setModalOpen}
        isLoading={false}
        isSuccess={false}
      />

      <Divider className="my-5" borderClassName="border-gray-200" aria-hidden="true" />

      <div className="flex flex-col md:flex-row box-border">
        <div className="flex-1 md:pr-4">
          <FormHeader title="Educación" />
          <div className="overflow-y-auto w-95">{educations?.map(buildEducation)}</div>
        </div>
        <div className="flex-1 md:pr-4">
          {/* Experiences */}
          <form onSubmit={handleSubmit(onSubmit)} method="POST">
            <div className="my-6">
              <EducationForm
                register={register}
                errors={errors}
                defaultExperience={defaultEducation}
                disabled={createEducationMutation.isLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
