import Button from 'components/Button';
import Title from 'components/Title';
import React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { pay } from 'services/payment';
import { getToken } from 'state/auth';

export default function PaySession() {
  // Global state
  const token = useRecoilValue(getToken);
  const { session, refetchSession } = useOutletContext();

  // RRD
  const navigate = useNavigate();

  // RQ
  const payActionMutation = useMutation((data) => pay(token, data), {
    onSuccess: async () => {
      await refetchSession();
      toast.success('La sesión se pagó exitosamente');
      navigate(-1);
    },
    onError: () => toast.error('Algo salió mal, verifique su correo.'),
  });

  // RHF
  const { handleSubmit } = useForm();

  const onSubmit = () => {
    payActionMutation.mutate({ ticket_id: session.ticket_id });
  };

  return (
    <div className="mt-9">
      <Title className="font-body leading-6 text-lg text-primary">Pagar la sesión</Title>
      <br />
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <Button
            disabled={payActionMutation.isLoading}
            type="submit"
            className="p-2 bg-secondary rounded-md  text-white"
          >
            Payment Intent
          </Button>

          <Button
            disabled={payActionMutation.isLoading}
            onClick={() => navigate(-1)}
            className="p-2 bg-red-500 rounded-md text-white"
          >
            Cancelar
          </Button>
        </div>
      </form>
    </div>
  );
}
