export const STATUS_PATIENT = {
  0: {
    tagColor: 'secondary',
    tag: 'Creado',
    withDot: false,
  },
  1: {
    tagColor: 'nineth',
    tag: 'Inactivo',
    withDot: false,
  },
  2: {
    tagColor: 'sixth',
    tag: 'En revisión',
    withDot: false,
  },
  3: {
    tagColor: 'tertiary',
    tag: 'En tratamiento',
    withDot: true,
  },
};

export const REQUEST_STATUS = {
  0: {
    tagColor: 'tertiary',
    tag: 'Creado',
    withDot: false,
  },
  1: {
    tagColor: 'secondary',
    tag: 'progreso',
    withDot: false,
  },
  2: {
    tagColor: 'sixth',
    tag: 'En revisión',
    withDot: false,
  },
  3: {
    tagColor: 'seven',
    tag: 'En propuesta',
    withDot: true,
  },
  4: {
    tagColor: 'fifth',
    tag: 'Se rechazó',
    withDot: false,
  },
  5: {
    tagColor: 'fifth',
    tag: 'Rechazado por paciente',
    withDot: false,
  },
};
