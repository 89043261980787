import { yupResolver } from '@hookform/resolvers/yup';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import Divider from 'components/Divider';
import FormHeader from 'components/FormHeader';
import Input from 'components/Input';
import ProfrilePictureInput from 'components/ProfilePictureInput';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { CreateHopSchema } from 'schemas/hop';
import { createHop } from 'services/hops';
import { getToken } from 'state/auth';

export default function NewHop() {
  // Global states
  const token = useRecoilValue(getToken);

  // Local states
  const [file, setFile] = useState(null);

  // RRD
  const navigate = useNavigate();

  // RHF
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(CreateHopSchema),
  });

  // Mutation
  const createHopMutation = useMutation(
    (data) => {
      createHop(token, data, file);
    },
    {
      onSuccess: () => {
        toast.success('Se guardo la información con exito.');
        navigate('/ic/hops');
      },
      onError: () => toast.error('Algo salio mal.'),
    },
  );

  // Handlers
  const onSubmit = (data) => {
    const { firstName, lastName, email } = data;

    createHopMutation.mutate({
      token,
      first_name: firstName,
      last_name: lastName,
      email,
    });
  };

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)} method="POST">
        <BasicLayout
          title="Nuevo jefe de psicólogos"
          button={
            <Button
              disabled={createHopMutation.isLoading}
              primary={true}
              className="border max-w-40% text-white font-medium rounded-md text-sm"
              type="submit"
            >
              Guardar
            </Button>
          }
        >
          <Divider className="mb-5" borderClassName="border-gray-200" aria-hidden="true" />
          <div>
            <div className="md:grid md:grid-cols-1 lg:grid-cols-3 md:gap-6 my-4 sm:my-0">
              <FormHeader title="Información básica" />
              <div className="md:mt-0 md:col-span-2">
                <div className="col-span-6 sm:col-span-3">
                  <div className="my-2">
                    <Input
                      id="firstName"
                      textLabel="Nombre"
                      type="text"
                      register={register}
                      registerConfig={{ required: true }}
                      withLabel
                      error={errors.firstName?.message}
                    />
                  </div>
                  <div className="my-2">
                    <Input
                      id="lastName"
                      textLabel="Apellido"
                      type="text"
                      register={register}
                      registerConfig={{ required: true }}
                      withLabel
                      error={errors.lastName?.message}
                    />
                  </div>
                  <div className="my-2">
                    <Input
                      id="email"
                      textLabel="Correo electrónico"
                      type="text"
                      register={register}
                      registerConfig={{ required: true }}
                      withLabel
                      error={errors.email?.message}
                    />
                  </div>

                  {/* Profile photo */}
                  <ProfrilePictureInput id="preview" setValue={setFile} />
                </div>
              </div>
            </div>
          </div>
          <Divider
            className="hidden sm:block py-5"
            borderClassName="border-gray-200"
            aria-hidden="true"
          />
        </BasicLayout>
      </form>
    </section>
  );
}
