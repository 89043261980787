const {
  ACCOMPANIMENT_CREATED,
  ACCOMPANIMENT_ACCEPTED,
  ACCOMPANIMENT_IN_PROGRESS,
  ACCOMPANIMENT_STOPPED_NO_PAYMENT,
  ACCOMPANIMENT_STOPPED_BY_PATIENT,
  ACCOMPANIMENT_STOPPED_BY_PSYCHOLOGIST,
  ACCOMPANIMENT_CANCELED_NO_PAYMENT,
  ACCOMPANIMENT_CANCELED_BY_PATIENT,
  ACCOMPANIMENT_CANCELED_BY_PSYCHOLOGIST,
  ACCOMPANIMENT_CANCELED_BY_ADMIN,
  ACCOMPANIMENT_FINALIZED,
} = require('utils/constants/accompaniment');

export const ACCOMPANIMENT_STATUSES_MENU = [
  {
    id: ACCOMPANIMENT_CREATED,
    name: 'Creado',
  },
  {
    id: ACCOMPANIMENT_ACCEPTED,
    name: 'Aceptado',
  },
  {
    id: ACCOMPANIMENT_IN_PROGRESS,
    name: 'En progreso',
  },
  {
    id: ACCOMPANIMENT_STOPPED_NO_PAYMENT,
    name: 'Pausa por falta de pago',
  },
  {
    id: ACCOMPANIMENT_STOPPED_BY_PATIENT,
    name: 'Pausa por Paciente',
  },
  {
    id: ACCOMPANIMENT_STOPPED_BY_PSYCHOLOGIST,
    name: 'Pausa por Dover',
  },
  {
    id: ACCOMPANIMENT_CANCELED_NO_PAYMENT,
    name: 'Cancelado por falta de pago',
  },
  {
    id: ACCOMPANIMENT_CANCELED_BY_PATIENT,
    name: 'Cancelado por Paciente',
  },
  {
    id: ACCOMPANIMENT_CANCELED_BY_PSYCHOLOGIST,
    name: 'Cancelado por Dover',
  },
  {
    id: ACCOMPANIMENT_CANCELED_BY_ADMIN,
    name: 'Cancelado por Administrador',
  },
  {
    id: ACCOMPANIMENT_FINALIZED,
    name: 'Finalizado',
  },
];
