import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

const PRIVATE_PATH = 'private';
const PAYMENTS_PATH = 'payments';
const PAYMENT_TICKETS_PATH = 'tickets';

export const listPaymentTickets = async (token, filters, url) => {
  /**
   * status -> 1 no payment, 2 payed
   * search -> filters for pat && psy emails
   */
  const { data } = await axios.get(
    url ? url : `${API_URL}${PAYMENTS_PATH}/${PAYMENT_TICKETS_PATH}/`,
    {
      headers: headers(token),
      params: url
        ? {}
        : {
            search: filters.search,
            status: filters.status,
            page_size: 8,
          },
    },
  );

  return data;
};

export const exportPaymentTickets = async (token, body) => {
  const { data } = await axios.post(
    `${API_URL}${PAYMENTS_PATH}/${PAYMENT_TICKETS_PATH}/export/`,
    body,
    {
      headers: headers(token),
    },
  );

  return data;
};

export const pay = async (token, body) => {
  const { data } = await axios.post(`${API_URL}${PRIVATE_PATH}/${PAYMENTS_PATH}/pay/`, body, {
    headers: headers(token),
  });

  return data;
};
