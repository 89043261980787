import { SaveIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import FormHeader from 'components/FormHeader';
import Input from 'components/Input';
import Loader from 'components/Loader';
import Select from 'components/Select';
import React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { CreateCouponsSchema } from 'schemas/coupons';
import { listCompanies } from 'services/companies';
import { createCoupon } from 'services/coupons';
import { getToken } from 'state/auth';

export default function CreateCoupon() {
  // Global states
  const token = useRecoilValue(getToken);

  // RHF
  const {
    reset,
    watch,
    register,
    setValue,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      discoutAmount: '',
      discoutPercentage: '',
    },
    resolver: yupResolver(CreateCouponsSchema),
  });
  console.log('🚀 ~ CreateCoupon ~ errors:', errors);

  const watchType = watch('type');
  const watchScope = watch('scope');

  // React Router
  const navigate = useNavigate();

  // React Query
  const listCompaniesQuery = useQuery(
    ['listCompanies', token],
    () => listCompanies(token, { search: '' }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const createCouponMutation = useMutation(
    (data) => {
      createCoupon(token, data);
    },
    {
      onSuccess: () => {
        toast.success('Se creó la compañia con exito.');
        reset();
      },
      onError: () => toast.error('Algo salio mal.'),
    },
  );

  const onChangeSelect = (field, value) => {
    setValue(field, value);
  };

  const onSubmit = (data) => {
    console.log('🚀 ~ onSubmit ~ data:', data);

    if (data.type === '0' && !data.discoutPercentage) {
      setError('type', { message: 'Ingrese un porcentaje' });
    } else if (data.type === '0' && data.discoutAmount) {
      setError('type', { message: 'Si el cupón es de tipo porcentaje no puede poner un monto' });
    } else if (data.type === '1' && !data.discoutAmount) {
      setError('type', { message: 'Ingrese un monto' });
    } else if (data.type === '1' && data.discoutPercentage) {
      setError('type', { message: 'Si el cupón es de tipo porcentaje no puede poner un monto' });
    }

    if (data.scope === '0' && data.company > 0) {
      setError('scope', {
        message: 'Si el cupón va dirigido a pacientes, no puede seleccionar una compañia',
      });
    } else if (data.scope === '1' && !data.company) {
      setError('scope', { message: 'Selecione una compañia' });
    }

    // createCouponMutation.mutate(data);
  };

  return (
    <BasicLayout title="Crear Cupón">
      <form onSubmit={handleSubmit(onSubmit)} method="POST" className="space-y-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 box-border">
          <div className="col-span-1 md:pr-4">
            <FormHeader
              title="Código"
              description={
                'El código del cupon tiene que ir en mayusculas, todo junto sin caracteres especiales.'
              }
            />
            <div className="my-2">
              <Input
                id="code"
                type="text"
                textPosition={'uppercase'}
                register={register}
                registerConfig={{ required: true }}
                withLabel
                error={errors.code?.message}
              />
            </div>

            <FormHeader
              title="Tipo"
              description={'El cupón puede descontar la cantidad por porcentaje o por monto.'}
            />
            <div className="my-2">
              <Select
                id="type"
                selectOptions={[
                  { id: 0, name: 'Porcentaje' },
                  { id: 1, name: 'Monto' },
                ]}
                register={register}
                registerConfig={{ required: true }}
                error={errors?.type?.message}
                withDefaultOption
              />
            </div>

            {watchType && watchType === '0' ? (
              <div className="my-2">
                <Input
                  id="discoutPercentage"
                  textLabel="Cantidad (%)"
                  type="number"
                  register={register}
                  registerConfig={{ required: true }}
                  withLabel
                  error={errors.discoutPercentage?.message}
                />
              </div>
            ) : null}

            {watchType && watchType === '1' ? (
              <div className="my-2">
                <Input
                  id="discoutAmount"
                  textLabel="Cantidad (USD)"
                  type="number"
                  register={register}
                  registerConfig={{ required: true }}
                  withLabel
                  error={errors.discoutAmount?.message}
                />
              </div>
            ) : null}
          </div>
          <div className="col-span-1 lg:pl-4">
            <FormHeader
              title="Alcance"
              description={'El cupón se puede asignar a un paciente o a una compañia.'}
            />
            <div className="my-2">
              <Select
                id="scope"
                selectOptions={[
                  { id: 0, name: 'Paciente' },
                  { id: 1, name: 'Compañia' },
                ]}
                register={register}
                registerConfig={{ required: true }}
                error={errors?.scope?.message}
                withDefaultOption
              />
            </div>

            {watchScope && watchScope === '1' ? (
              <div className="my-2">
                <Select
                  id="company"
                  selectOptions={
                    listCompaniesQuery.isSuccess ? listCompaniesQuery.data.results : []
                  }
                  register={register}
                  registerConfig={{ required: true }}
                  withDefaultOption
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-full flex justify-end">
          <br />
          <Button
            disabled={createCouponMutation.isLoading}
            type="submit"
            className="btn-tertiary rounded-md w-full lg:w-48"
            icon={
              createCouponMutation.isLoading ? (
                <>
                  <Loader className="text-white h-6 w-6" /> Guardando
                </>
              ) : (
                <SaveIcon className="text-white h-6 w-6 pr-2" />
              )
            }
          >
            {createCouponMutation.isLoading ? '' : 'Guardar'}
          </Button>
        </div>
      </form>
    </BasicLayout>
  );
}
