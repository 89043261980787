import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

const ACCOMPANIMENTS_PATH = 'accompaniments/';
const PRIVATE_PATH = 'private/';

export const getPrivateAccompaniment = async (token, id) => {
  // id -> patient id
  const { data } = await axios.get(`${API_URL}${ACCOMPANIMENTS_PATH}${PRIVATE_PATH}${id}/detail`, {
    headers: headers(token),
  });

  return data;
};

export const listAccompaniments = async (token, filters, url) => {
  const { data } = await axios.get(url ? url : `${API_URL}${PRIVATE_PATH}${ACCOMPANIMENTS_PATH}`, {
    headers: headers(token),
    params: url
      ? {}
      : {
          status: filters.status,
          search: filters.search,
          page_size: 8,
        },
  });
  return data;
};

export const listSessions = async (token, id) => {
  const { data } = await axios.get(`${API_URL}${ACCOMPANIMENTS_PATH}${id}/sessions/`, {
    headers: headers(token),
  });
  return data;
};

export const updateAccompaniment = async (token, id, body) => {
  const { data } = await axios.patch(
    `${API_URL}${PRIVATE_PATH}${ACCOMPANIMENTS_PATH}${id}/`,
    body,
    {
      headers: headers(token),
    },
  );
  return data;
};
