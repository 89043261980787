import { useState } from 'react';

export const useUserCountriesState = () => {
  // LOCAL STATES
  const [states, setStates] = useState([]);

  // ? -- setCountriesState --
  // ? setCountriesState is to set the states of the user specific country
  // ? this is only used after the getCountriesWithState query is successfull
  const setCountriesState = (countriesData, data) => {
    let countryIndex = null;
    // In our countries state we look for the one that the user has.
    countriesData.map((country, index) => {
      if (country.id === data.address.country) {
        countryIndex = index;
      }
    });
    // We set the states that are of that country
    setStates(countriesData[parseInt(countryIndex)].states);
  };

  // ? -- onChangeCountrySelect --
  // ? onChangeCountrySelect is to set the states of the user specific country in a onChange event
  // ? this is only used in the country select onChange event.
  const onChangeCountrySelect = (event, countriesData) => {
    let countryIndex = null;
    countriesData.map((country, index) => {
      // In our countries state we look for the one that we picked in our select.
      if (country.id == event.currentTarget.value) {
        countryIndex = index;
      }
    });
    // We set the states that are of that country
    setStates(countriesData[parseInt(countryIndex)].states);
  };

  return { setCountriesState, onChangeCountrySelect, states };
};
