import Divider from 'components/Divider';
import IconSpanItem from 'components/IconSpanItem';
import PropTypes from 'prop-types';
import React from 'react';

const iconSpanItemIteration = (array) => {
  let icons = [];
  for (let item in array) {
    icons.push(
      <IconSpanItem icon={array[parseInt(item)]['icon']} key={Math.random()}>
        {array[parseInt(item)]['text']}
      </IconSpanItem>,
    );
  }
  return icons;
};

export default function EducationItem({ title, iconSpanItem, divider, className, children }) {
  // iconSpanItem is a dynamic objects with array where we send only 2 items, the icon and the span
  return (
    <div className={`${className}`}>
      <div className="px-5 py-4">
        <div className="flex justify-between items-center">
          <span className="text-sm font-body text-primary font-bold">{title}</span>
          {children}
        </div>
        <div className="flex flex-col mt-2">{iconSpanItemIteration(iconSpanItem)}</div>
      </div>
      {divider && <Divider className="md:hidden" />}
    </div>
  );
}

EducationItem.propTypes = {
  title: PropTypes.string.isRequired,
  tag: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  tagColor: PropTypes.string,
  divider: PropTypes.bool,
  iconSpanItem: PropTypes.any,
};

EducationItem.defaultProps = {
  tagColor: 'primary',
  divider: true,
};
