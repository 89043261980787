import { DocumentDownloadIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ExportPaymentTickesSchema } from 'schemas/payment';
import { exportPaymentTickets } from 'services/payment';
import { getToken } from 'state/auth';

export default function PaymentExport() {
  // Global states
  const token = useRecoilValue(getToken);

  // Local states
  const [classDisabled] = useState(false);

  // RRD
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(ExportPaymentTickesSchema),
  });

  // Mutation
  const exportPaymentTicketsMutation = useMutation(
    ({ token, email, date, statuses, fortnight }) => {
      exportPaymentTickets(token, { email, date, statuses, fortnight });
    },
    {
      onSuccess: () => {
        toast.success('Llegará un correo con la liga del archivo.');
        navigate(-1);
      },
      onError: () => toast.error('Algo salio mal.'),
    },
  );

  const onSelectHandler = (event, name) => {
    setValue(name, event.target.value);
  };

  const onSubmit = ({ email, date, status, fortnight }) => {
    const parsedData = { email, date, fortnight };
    if (status === 1) {
      parsedData['statuses'] = [1];
    } else if (status === 2) {
      parsedData['statuses'] = [2];
    } else if (status === 3) {
      parsedData['statuses'] = [1, 2];
    }
    exportPaymentTicketsMutation.mutate({ token, ...parsedData });
  };

  return (
    <BasicLayout title="Exportar Ticket">
      <div className="flex flex-col lg:flex-row box-border">
        <div className="flex-1 mr-0 md:mr-4">
          <form onSubmit={handleSubmit(onSubmit)} method="POST">
            <div>
              <div className="my-6">
                <div className="flex flex-col md:flex-row box-border">
                  <div className="flex-1 md:pr-4">
                    <div className="col-span-6 sm:col-span-3">
                      <div className="flex flex-col md:flex-row my-2">
                        <div className="flex-1 md:mr-2">
                          <Input
                            id="email"
                            textLabel="Correo electrónico"
                            type="text"
                            register={register}
                            registerConfig={{ required: true }}
                            withLabel
                            disabled={classDisabled}
                            error={errors.email?.message}
                          />
                        </div>
                        <div className="flex-1 md:mr-2 mt-2 md:mt-0">
                          <Select
                            id="status"
                            textLabel="Estatus"
                            withIndex={false}
                            selectOptions={[
                              { id: 1, name: 'Sin pago' },
                              { id: 2, name: 'Pagado' },
                              { id: 3, name: 'Todos' },
                            ]}
                            onChange={(e) => onSelectHandler(e, 'status')}
                            register={register}
                            registerConfig={{ required: true }}
                            error={errors.status?.message}
                            disabled={classDisabled}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row my-2">
                        <div className="flex-1 md:mr-2">
                          <Input
                            id="date"
                            textLabel="Mes"
                            type="month"
                            register={register}
                            registerConfig={{ required: true }}
                            withLabel
                            disabled={classDisabled}
                            error={errors.date?.message}
                          />
                        </div>
                        <div className="flex-1 md:mr-2 mt-2 md:mt-0">
                          <Select
                            id="fortnight"
                            textLabel="Quincena"
                            withIndex={false}
                            selectOptions={[
                              { id: 1, name: 'Primera Quincena' },
                              { id: 2, name: 'Segunda Quincena' },
                            ]}
                            onChange={(e) => onSelectHandler(e, 'fortnight')}
                            register={register}
                            registerConfig={{ required: true }}
                            error={errors.fortnight?.message}
                            disabled={classDisabled}
                          />
                        </div>
                      </div>
                      <br />
                      <Button
                        disabled={false}
                        primary
                        type="submit"
                        icon={<DocumentDownloadIcon className="h-5 w-5 text-white mr-2" />}
                        className="border  font-medium rounded-md text-sm"
                      >
                        Exportar
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="flex-1 mr-0 md:mr-4"></div>
      </div>
    </BasicLayout>
  );
}
