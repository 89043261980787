/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from 'prop-types';
import React from 'react';

import Tag from './Tag';

export default function LiItem({
  id,
  image,
  firstText,
  secondText,
  thirdText,
  ThirdTextIcon,
  tag,
  tagColor,
  withDot,
  onClick,
}) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <li
      className="flex justify-between gap-x-6 py-5 hover:cursor-pointer hover:bg-gray-100 px-4"
      onClick={onClick}
    >
      <div className="flex min-w-0 gap-x-4">
        {image ? (
          <img
            className="h-12 w-12 flex-none rounded-full bg-gray-50 object-cover"
            src={image}
            alt=""
          />
        ) : null}

        <div className="min-w-0 flex-auto">
          <p className="text-sm font-semibold leading-6 text-gray-900">{firstText}</p>
          <p className="mt-1 truncate text-xs leading-5 text-gray-500">{secondText}</p>
        </div>
      </div>
      <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
        <div className="mt-1 flex items-center gap-x-1.5">
          <Tag selected tag={tag} color={tagColor} withDot={withDot} />
        </div>
        <div className="mt-1 flex items-center ">
          {ThirdTextIcon}
          <p className={`font-body m-0 text-gray-500 text-xs`}>{thirdText}</p>
        </div>
      </div>
    </li>
  );
}

LiItem.propTypes = {
  id: PropTypes.number,
  image: PropTypes.string,
  firstText: PropTypes.any,
  secondText: PropTypes.any,
  thirdText: PropTypes.any,
  ThirdTextIcon: PropTypes.element,
  tag: PropTypes.string,
  tagColor: PropTypes.string,
  withDot: PropTypes.bool,
  onClick: PropTypes.func,
};
