import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';

import { auth as authState } from '../../state/auth';

export default function AdminAuth({ children }) {
  // Global state
  const [auth] = useRecoilState(authState);

  const role = auth?.user?.role;

  if (role !== 'admin') {
    return 'No tiene permisos para acceder a está página.';
  }
  return children;
}

AdminAuth.propTypes = {
  children: PropTypes.any.isRequired,
};
