import * as Yup from 'yup';

// CONSTANTS
const requiredField = 'Este campo es obligatorio';

// SCHEMAS
export const SearchCouponsSchema = Yup.object().shape({
  search: Yup.string().required(requiredField),
});

export const CreateCouponsSchema = Yup.object().shape({
  code: Yup.string().uppercase().required(requiredField),
  type: Yup.string().required(requiredField),
  discoutPercentage: Yup.string().nullable(),
  discoutAmount: Yup.string().nullable(),
  scope: Yup.string().required(requiredField),
  company: Yup.string().nullable(),
});
