import { PlusIcon, SearchIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import Input from 'components/Input';
import LiItem from 'components/LiItem';
import Loader from 'components/Loader';
import Title from 'components/Title';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { FilterCompanyPatientSchema } from 'schemas/company';
import { getCompany, listCompanyPatients } from 'services/companies';
import { getToken } from 'state/auth';
import { isArrayEmpty } from 'utils/base';

export default function CompanyListPatients() {
  // GLOBAL STATES
  const token = useRecoilValue(getToken);

  // LOCAL STATES
  const [filters, setFilters] = useState({
    search: '',
  });
  const [URL, setURL] = useState(null);

  // PARAMS
  const { id } = useParams();

  // RRD
  const navigate = useNavigate();

  // RHF
  const { register, setValue, handleSubmit } = useForm({
    defaultValues: {
      search: '',
    },
    resolver: yupResolver(FilterCompanyPatientSchema),
  });

  // REACT QUERY
  const getCompanyQuery = useQuery(['getCompany', token, id], () => getCompany(token, id), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { data, isSuccess, isLoading, isRefetching, refetch } = useQuery(
    ['listCompanyPatients', token, id],
    () => listCompanyPatients(token, id),
    {
      enabled: getCompanyQuery.isSuccess,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    refetch();
  }, [filters, URL, refetch]);

  const onSubmit = (values) => {
    setURL(null);
    setFilters(values);
  };

  const onClickPagination = (isNext) => {
    const URL = isNext ? data.next : data.previous;
    setURL(URL);
  };

  return (
    <BasicLayout
      title={`Company ${getCompanyQuery.isSuccess ? getCompanyQuery.data?.name : ''}`}
      button={
        <div className="w-full lg:w-60">
          <Button
            className="rounded-md bg-primary text-white p-2"
            icon={<PlusIcon className="h-5 w-5 text-white mr-2" />}
            onClick={() => navigate('add-patients')}
          >
            Asociar Pacientes
          </Button>
        </div>
      }
    >
      <Title className="text-title text-md mt-4">Lista de Usuarios</Title>
      <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-4">
        <div className="col-span-1 sticky">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <Input
              id="search"
              type="text"
              register={register}
              registerConfig={{}}
              icon={<SearchIcon className="h-6 w-6 text-gray-400" />}
              leftIcon={true}
              disabled={false}
            />

            <Button
              disabled={isLoading}
              type="submit"
              className="btn-tertiary rounded-md"
              icon={
                isLoading ? (
                  <>
                    <Loader className="text-white h-6 w-6" /> Procesando...
                  </>
                ) : null
              }
            >
              {isLoading ? '' : 'Buscar'}
            </Button>
          </form>
        </div>
        <div className="md:col-span-3 p-4">
          {isLoading || isRefetching ? (
            <div className="w-full flex justify-center items-center text-center font-body mt-10">
              <Loader /> Buscando...
            </div>
          ) : null}

          {isSuccess && isArrayEmpty(data.results) ? (
            <div className="text-center font-body mt-10">No se encontraron resultados</div>
          ) : null}

          {isSuccess && !isArrayEmpty(data.results) && (
            <>
              <ul className="divide-y divide-gray-100">
                {data.results.map((patient, index) => {
                  console.log('🚀 ~ {data.results.map ~ patient:', patient);
                  return (
                    <LiItem
                      id={patient.id}
                      key={`patient-${index}`}
                      firstText={`${patient.first_name} ${patient.last_name}`}
                      tag={`#${patient.id}`}
                      tagColor={'tertiary'}
                      onClick={() => navigate(`${patient.id}`)}
                    />
                  );
                })}
              </ul>
            </>
          )}

          <nav
            className="mt-4 flex items-center justify-between border-t border-gray-200 bg-white py-3 "
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">1</span> to{' '}
                <span className="font-medium">10</span> of <span className="font-medium">20</span>{' '}
                results
              </p>
            </div>

            <div className="flex flex-1 justify-between sm:justify-end gap-4">
              <div className="w-30">
                <Button
                  disabled={data?.previous === null || isRefetching}
                  onClick={() => onClickPagination(false)}
                  className="rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Previous
                </Button>
              </div>
              <div className="w-30">
                <Button
                  disabled={data?.next === null || isRefetching}
                  onClick={() => onClickPagination(true)}
                  className="rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Next
                </Button>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </BasicLayout>
  );
}
