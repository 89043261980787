const HOST = process.env.REACT_APP_API_HOST;

const API_VERSION = 'v1/';

export const API_URL = `${HOST}${API_VERSION}`;

export const SPANISH_MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
