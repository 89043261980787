import { InformationCircleIcon } from '@heroicons/react/outline';
import { DocumentDownloadIcon, SearchIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import Input from 'components/Input';
import LiItem from 'components/LiItem';
import Loader from 'components/Loader';
import Select from 'components/Select';
import Title from 'components/Title';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { FilterPatientSchema } from 'schemas/patient';
import { listPaymentTickets } from 'services/payment';
import { getToken } from 'state/auth';
import { isArrayEmpty } from 'utils/base';

const statuses = [
  { id: 1, name: 'No Pagados' },
  { id: 2, name: 'Pagados' },
];

export default function ListPaymentTickets() {
  // GLOBAL STATES
  const token = useRecoilValue(getToken);

  // LOCAL STATES
  const [filters, setFilters] = useState({
    status: 1,
    search: '',
  });
  const [URL, setURL] = useState(null);

  // RRD
  const navigate = useNavigate();

  // RHF
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      status: 1,
      search: '',
    },
    resolver: yupResolver(FilterPatientSchema),
  });

  // REACT QUERY
  const { data, isLoading, isSuccess, isRefetching, refetch } = useQuery(
    ['listPaymentTickets', token, filters, URL],
    () => listPaymentTickets(token, filters, URL),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    refetch();
  }, [filters, URL, refetch]);

  const onChangeSelect = (field, value) => {
    setValue(field, value);
  };

  const onSubmit = (values) => {
    setURL(null);
    setFilters(values);
  };

  const onClickPaymentTicket = (id) => {
    // TODO
    navigate(`detail/${id}`);
  };

  const onClickPagination = (isNext) => {
    const URL = isNext ? data.next : data.previous;
    setURL(URL);
  };

  return (
    <BasicLayout
      title="Pacientes"
      button={
        <div className="w-full lg:w-60">
          <Button
            primary={true}
            className="rounded-md bg-primary text-white p-2"
            icon={<DocumentDownloadIcon className="h-5 w-5 text-white mr-2" />}
            onClick={() => navigate('export')}
          >
            Exportar ticket
          </Button>
        </div>
      }
    >
      <Title className="flex items-center">
        <InformationCircleIcon className="h-8 w-8 text-secondary mr-2" /> Todas las fechas de esta
        página están en UTC.
      </Title>
      <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-4">
        <div className="col-span-1 p-4 sticky">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <Input
              id="search"
              type="text"
              register={register}
              registerConfig={{}}
              icon={<SearchIcon className="h-6 w-6 text-gray-400" />}
              leftIcon={true}
              disabled={false}
            />

            <Select
              id="status"
              textLabel="Estatus"
              withIndex={false}
              selectOptions={statuses}
              onChange={(e) => onChangeSelect('status', e.target.value)}
              register={register}
              registerConfig={{ required: true }}
              error={errors.degree?.message}
            />

            <Button
              disabled={isLoading}
              type="submit"
              className="btn-tertiary rounded-md"
              icon={
                isLoading ? (
                  <>
                    <Loader className="text-white h-6 w-6" /> Procesando...
                  </>
                ) : null
              }
            >
              {isLoading ? '' : 'Buscar'}
            </Button>
          </form>
        </div>
        <div className="md:col-span-3 p-4">
          {isLoading || isRefetching ? (
            <div className="w-full flex justify-center items-center text-center font-body mt-10">
              <Loader /> Buscando...
            </div>
          ) : null}

          {isSuccess && isArrayEmpty(data.results) ? (
            <div className="text-center font-body mt-10">No se encontraron resultados</div>
          ) : null}

          {isSuccess && !isArrayEmpty(data.results) && (
            <>
              <ul className="divide-y divide-gray-100">
                {data.results.map((ticket, index) => {
                  const paymentDate = moment(ticket.payment_date)
                    .utc()
                    .format('DD [de] MMMM [del] YYYY');
                  let paidOn = '-';
                  if (ticket.paid_on) {
                    paidOn = moment(ticket.paid_on).utc().format('DD [de] MMMM [del] YYYY');
                  }
                  return (
                    <LiItem
                      id={ticket.id}
                      key={`ticket-${index}`}
                      firstText={`Ticket de pago #${ticket.id}`}
                      secondText={
                        <>
                          Fecha de corte: <strong>{paymentDate}</strong>
                        </>
                      }
                      thirdText={
                        <>
                          Fecha de pago: <strong>{paidOn}</strong>
                        </>
                      }
                      tag={`${ticket.total_amount}.00 USD`}
                      tagColor={`tertiary`}
                      withDot={false}
                      onClick={() => onClickPaymentTicket(ticket.id)}
                    />
                  );
                })}
              </ul>
            </>
          )}

          <nav
            className="mt-4 flex items-center justify-between border-t border-gray-200 bg-white py-3 "
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">1</span> to{' '}
                <span className="font-medium">10</span> of <span className="font-medium">20</span>{' '}
                results
              </p>
            </div>

            <div className="flex flex-1 justify-between sm:justify-end gap-4">
              <div className="w-30">
                <Button
                  disabled={data?.previous === null || isRefetching}
                  onClick={() => onClickPagination(false)}
                  className="rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Previous
                </Button>
              </div>
              <div className="w-30">
                <Button
                  disabled={data?.next === null || isRefetching}
                  onClick={() => onClickPagination(true)}
                  className="rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Next
                </Button>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </BasicLayout>
  );
}
