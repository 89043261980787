export const PSYCHOLOGIST_DEGREES = {
  0: {
    degree: 'Becario',
    color: 'fourth',
    sessionCost: '20',
  },
  1: {
    degree: 'Dover',
    color: 'tertiary',
    sessionCost: '46',
  },
  2: {
    degree: 'SuperDover',
    color: 'secondary',
    sessionCost: '56',
  },
  3: {
    degree: 'UltraDover',
    color: 'primary',
    sessionCost: '76',
  },
};
