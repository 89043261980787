import { CalendarIcon, ClockIcon, PencilAltIcon } from '@heroicons/react/solid';
import Divider from 'components/Divider';
import EducationItem from 'components/EducationItem';
import Loader from 'components/Loader';
import Title from 'components/Title';
import React from 'react';
import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getPatientsSessions } from 'services/patient';
import { getToken } from 'state/auth';
import { isObjectEmpty } from 'utils/base';
import { getDateAndTimezone, getMomentDateCalendar } from 'utils/dates';

export default function CalendarSessions() {
  // GLOBAL STATES
  const token = useRecoilValue(getToken);

  // PARAMS
  const { id } = useParams(); //patient.id

  // REACT QUERY
  const {
    data: sessionsData,
    isSuccess: sessionsIsSuccess,
    // eslint-disable-next-line
    isLoading: sessionsIsLoading,
  } = useQuery(['getPatientsSessions', token, id], () => getPatientsSessions(token, id));

  if (sessionsIsSuccess) {
    // eslint-disable-next-line
    console.log('🚀 ~ file: index.js ~ line 22 ~ Calendar ~ sessionsData', sessionsData);
  }

  // MEDIA QUERIES
  // eslint-disable-next-line
  const isBigScreen = useMediaQuery({ query: '(min-width: 1280px)' });

  // CONSTANTS
  // eslint-disable-next-line
  const selectedDates = [];
  const dateFormat = 'DD/MM/YYYY';

  // RENDERS
  const renderEverySection = (session, patient) => {
    return (
      <EducationItem
        key={session.id}
        title={`Sesión ${session.number}`}
        className="flex-1 lg:max-w-336px md:min-w-300px md:m-2 md:rounded-lg md:shadow-md"
        iconSpanItem={[
          {
            icon: <CalendarIcon className="h-6 w-6 mr-2 text-gray-400" />,
            text: (
              <p className="font-body text-xs font-medium text-gray-400 m-0">
                {getDateAndTimezone(session.start_date, dateFormat).sessionDate}
              </p>
            ),
          },
          {
            icon: <ClockIcon className="h-6 w-6 mr-2 text-gray-400" />,
            text: (
              <p className="font-body text-xs font-medium text-gray-400 m-0">
                {getDateAndTimezone(session.start_date, dateFormat).sessionTime}
              </p>
            ),
          },
          {
            icon: (
              <img
                className="h-6 w-6 mr-2 rounded-full hidden md:block"
                src={patient.photo_URL}
                alt=""
              />
            ),
            text: (
              <p className="font-body hidden md:block text-xs font-medium text-gray-400 m-0">
                con <strong>{`${patient.first_name} ${patient.last_name}`}</strong>
              </p>
            ),
          },
        ]}
      >
        {<PencilAltIcon className="h-6 w-6 text-gray-400" />}
      </EducationItem>
    );
  };

  // eslint-disable-next-line
  const renderSessionSections = (data) => {
    return (
      <div>
        {data.today && (
          <div>
            <Title className="text-black text-base md:hidden">Hoy</Title>
            <div className="flex flex-col mb-8 md:my-0 border rounded-md border-gray-300 md:border-none">
              <Divider
                text="Hoy"
                centered={false}
                textColor="text-black"
                className="my-3 hidden md:block"
              />
              <div className="flex flex-col md:flex-row md:flex-wrap align-items rounded-lg">
                {data.today.map((session) => {
                  const patient = session.accompaniment.patient;
                  selectedDates.push(getMomentDateCalendar(session.start_date));
                  return renderEverySection(session, patient);
                })}
              </div>
            </div>
          </div>
        )}
        {data.current_week && (
          <div>
            <Title className="text-black text-base md:hidden">Esta semana</Title>
            <div className="flex flex-col mb-8 md:my-0 border rounded-md border-gray-300 md:border-none">
              <Divider
                text="Esta semana"
                centered={false}
                textColor="text-black"
                className="my-3 hidden md:block"
              />
              <div className="flex flex-col md:flex-row md:flex-wrap align-items rounded-lg">
                {
                  // eslint-disable-next-line
                  data.current_week.map((session) => {
                    const patient = session.accompaniment.patient;
                    selectedDates.push(getMomentDateCalendar(session.start_date));
                    return renderEverySection(session, patient);
                  })
                }
              </div>
            </div>
          </div>
        )}
        {data.next_week && (
          <div>
            <Title className="text-black text-base md:hidden">Siguiente semana</Title>
            <div className="flex flex-col mb-8 md:my-0 border rounded-md border-gray-300 md:border-none">
              <Divider
                text="Siguiente semana"
                centered={false}
                textColor="text-black"
                className="my-3 hidden md:block"
              />
              <div className="flex flex-col md:flex-row md:flex-wrap align-items rounded-lg">
                {
                  // eslint-disable-next-line
                  data.next_week.map((session) => {
                    const patient = session.accompaniment.patient;
                    selectedDates.push(getMomentDateCalendar(session.start_date));
                    return renderEverySection(session, patient);
                  })
                }
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="lg:border lg:border-gray-300 rounded-md md:shadow-none md:border-none">
        {sessionsIsLoading && (
          <>
            <Loader /> Procesando...
          </>
        )}
        {sessionsIsSuccess && renderSessionSections(sessionsData)}
        {/* Error */}
        {sessionsIsSuccess && !isObjectEmpty(sessionsData) && (
          <div className="hidden md:block">
            <Divider
              text="Calendario"
              centered={false}
              textColor="text-black"
              className="my-3 hidden md:block"
            />
            <div className="flex">
              {/* <Calendar daySize={isBigScreen ? 40 : 30} selectedDatesOption={selectedDates} /> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
