import PropTypes from 'prop-types';
import React from 'react';

export default function Checkbox({
  id,
  text,
  error,
  checked,
  onChange,
  register,
  labelClasses,
  registerConfig,
  disabled,
}) {
  let borderClasses = 'border-gray-300 focus:ring-tertiary focus:border-indigo-500';
  let _labelClasses = labelClasses ? labelClasses : 'text-white';
  if (error) {
    _labelClasses = 'text-pink-700';
    borderClasses = 'border-pink-300 focus:ring-pink-500 focus:border-pink-500 text-pink-500';
  }
  if (disabled) {
    _labelClasses = 'text-gray-300';
    borderClasses = 'border-gray-300 text-gray-300';
  }

  return (
    <fieldset className="space-y-5">
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            id={id}
            {...register(id, registerConfig)}
            aria-describedby="comments-description"
            name={id}
            type="checkbox"
            disabled={disabled}
            className={`focus:ring-indigo-500 h-4 w-4 text-tertiary border-gray-300 rounded ${_labelClasses} ${borderClasses}`}
            onChange={onChange}
            checked={checked}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={id} className={`leading-5 font-default text-white ${_labelClasses}`}>
            {text}
          </label>
        </div>
      </div>
      {error && <p className="text-sm font-body font-medium text-red-500 pt-2">{error}</p>}
    </fieldset>
  );
}

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  register: PropTypes.func,
  disabled: PropTypes.bool,
  labelClasses: PropTypes.string,
  registerConfig: PropTypes.object,
  error: PropTypes.string,
};

Checkbox.defaultProps = {};
