import { CreditCardIcon } from '@heroicons/react/outline';
import {
  CheckIcon,
  // ClockIcon,
  // CreditCardIcon,
  // UserRemoveIcon,
  // XCircleIcon,
} from '@heroicons/react/solid';
import {
  SESSION_CANCELED,
  SESSION_CREATED,
  SESSION_FINALIZED,
  SESSION_IN_PROGRESS,
  SESSION_PAYED,
  SESSION_SCHEDULED,
  SESSION_TIME_COMPLETED,
  SESSION_UNPAYED,
} from 'utils/constants/session';

// const cancelAction = {
//   title: 'Cancelar',
//   description: 'Some description 1',
//   icon: <XCircleIcon className="h-8 w-8 text-red-400" />,
//   navigate: 'cancel',
// };
// const rescheduleAction = {
//   title: 'Reagendar',
//   description: 'Some description 2',
//   icon: <ClockIcon className="h-8 w-8 text-primary" />,
//   navigate: 'reschedule',
// };
const payAction = {
  title: 'Pagar',
  description: 'Some description 3',
  icon: <CreditCardIcon className="h-8 w-8 text-green-500" />,
  navigate: 'pay',
};

// const noShowAction = {
//   title: 'El paciente no se presento',
//   description: 'Some description 6',
//   icon: <UserRemoveIcon className="h-8 w-8 text-red-500" />,
//   navigate: 'noShow',
// };

// const finalizeAction = {
//   title: 'Finalizar la sesión',
//   description: 'Some description 6',
//   icon: <CheckIcon className="h-8 w-8 text-green-500" />,
//   navigate: 'finalize',
// };

const actionsByStatus = {
  [SESSION_CREATED]: new Set([]),
  [SESSION_SCHEDULED]: new Set([payAction]),
  [SESSION_PAYED]: new Set([]),
  [SESSION_UNPAYED]: new Set([payAction]),
  [SESSION_CANCELED]: new Set([]),
  [SESSION_IN_PROGRESS]: new Set([]),
  [SESSION_TIME_COMPLETED]: new Set([]),
  [SESSION_FINALIZED]: new Set([]),
};

export const getActionsByStatus = (status) => {
  return actionsByStatus[String(status)];
};
