import { AcademicCapIcon, CashIcon, UserGroupIcon } from '@heroicons/react/solid';
import ImageCard from 'components/ImageCard';
import React from 'react';
import { Link } from 'react-router-dom';

export default function HomeSession() {
  return (
    <div className="flex flex-col px-4 lg:flex-row items-center justify-between w-full">
      <Link to="psychologists" className="w-full lg:max-w-xs mx-2">
        <ImageCard
          title="Psicólogos"
          className="bg-primary my-2 lg:my-0 bg-img-02 z-0 min-h-108"
          Icon={
            <AcademicCapIcon className="h-10 w-10 font-light bg-white p-2 text-primary rounded-full" />
          }
        ></ImageCard>
      </Link>
      <Link to="patients" className="w-full lg:max-w-xs mx-2">
        <ImageCard
          title="Pacientes"
          className="bg-primary my-2 lg:my-0 bg-img-01 z-0 min-h-108"
          Icon={
            <UserGroupIcon className="h-10 w-10 font-light bg-white p-2 text-secondary rounded-full" />
          }
        ></ImageCard>
      </Link>
      <Link to="payments" className="w-full lg:max-w-xs mx-2">
        <ImageCard
          title="Pagos"
          className="bg-primary my-2 lg:my-0 bg-img-03 z-0 min-h-108"
          Icon={
            <CashIcon className="h-10 w-10 font-light bg-white p-2 text-secondary rounded-full" />
          }
        ></ImageCard>
      </Link>
    </div>
  );
}
