import 'moment/locale/es';

import Button from 'components/Button';
import FormHeader from 'components/FormHeader';
import Input from 'components/Input';
import PropTypes from 'prop-types';
import React from 'react';

export default function Experiences({ fieldArray, register, errors, defaultExperience }) {
  const buildExperience = (field, index) => {
    return (
      <div key={field.id} className="py-2 px-4 rounded-md bg-gray-50 mb-4">
        <div className="flex flex-col md:flex-row my-2">
          <div className="flex-1 md:mr-2">
            <Input
              id={`experiences.${index}.title`}
              type="text"
              withLabel
              textLabel="Titulo"
              register={register}
              registerConfig={{}}
              error={errors?.[String(index)]?.title?.message}
            />
          </div>
          <div className="flex-1 md:ml-2">
            <Input
              id={`experiences.${index}.location`}
              type="text"
              withLabel
              textLabel="Ubicacion"
              register={register}
              registerConfig={{}}
              error={errors?.[String(index)]?.location?.message}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row my-2">
          <div className="flex-1 md:mr-2">
            <Input
              id={`experiences.${index}.company`}
              type="text"
              withLabel
              textLabel="Compania"
              register={register}
              registerConfig={{}}
              error={errors?.[String(index)]?.company?.message}
            />
          </div>
          <div className="flex-1 md:ml-2">
            <Input
              id={`experiences.${index}.charge`}
              type="text"
              withLabel
              textLabel="Cargo"
              register={register}
              registerConfig={{}}
              error={errors?.[String(index)]?.charge?.message}
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row my-2">
          <div className="flex-1 md:mr-2">
            <Input
              id={`experiences.${index}.start_date`}
              withLabel
              type="date"
              textLabel="Fecha de inicio"
              register={register}
              registerConfig={{}}
              error={errors?.[String(index)]?.start_date?.message}
            />
          </div>
          <div className="flex-1 md:ml-2">
            <Input
              id={`experiences.${index}.end_date`}
              withLabel
              type="date"
              textLabel="Fecha de cierre"
              register={register}
              registerConfig={{}}
              error={errors?.[String(index)]?.end_date?.message}
            />
          </div>
        </div>
        {index === 0 ? null : (
          <Button
            className="bg-red-400 border  text-white font-medium rounded-md text-sm my-4"
            onClick={() => fieldArray.remove(index)}
          >
            Eliminar experiencia
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      <FormHeader title="Experiencias" />
      <p className="text-xs text-gray-400">
        Si continua con la experiencia, deje el campo <strong>Fecha de cierre</strong> vacio.
      </p>
      <br />
      {fieldArray.fields.map(buildExperience)}
      <Button
        primary={true}
        className="border text-white font-medium rounded-md text-sm my-4"
        onClick={() => fieldArray.append(defaultExperience)}
      >
        Agregar experiencia
      </Button>
    </>
  );
}

Experiences.propTypes = {
  fieldArray: PropTypes.any.isRequired,
  register: PropTypes.func.isRequired,
  defaultExperience: PropTypes.object.isRequired,
  errors: PropTypes.array,
};

Experiences.defaultProps = {};
