import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

const EXPERIENCES_PATH = 'experiences/';
const EDUCATIONS_PATH = 'educations/';

export const createExperience = async (token, body) => {
  const { data } = await axios.post(`${API_URL}${EXPERIENCES_PATH}`, body, {
    headers: headers(token),
  });
  return data;
};

export const listExperiences = async (token, catherID) => {
  const { data } = await axios.get(`${API_URL}${EXPERIENCES_PATH}${catherID}/cather`, {
    headers: headers(token),
  });
  return data;
};

export const destroyExperience = async (token, experienceId) => {
  const { data } = await axios.delete(`${API_URL}${EXPERIENCES_PATH}${experienceId}/`, {
    headers: headers(token),
  });
  return data;
};

export const createEducation = async (token, body) => {
  const { data } = await axios.post(`${API_URL}${EDUCATIONS_PATH}`, body, {
    headers: headers(token),
  });
  return data;
};

export const listEducations = async (token, catherID) => {
  const { data } = await axios.get(`${API_URL}${EDUCATIONS_PATH}${catherID}/cather/`, {
    headers: headers(token),
  });
  return data;
};

export const destroyEducation = async (token, edicationId) => {
  const { data } = await axios.delete(`${API_URL}${EDUCATIONS_PATH}${edicationId}/`, {
    headers: headers(token),
  });
  return data;
};
