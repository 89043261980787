export const ACCOMPANIMENT_CREATED = 0;
export const ACCOMPANIMENT_ACCEPTED = 1;
export const ACCOMPANIMENT_IN_PROGRESS = 2;
export const ACCOMPANIMENT_STOPPED_NO_PAYMENT = 3;
export const ACCOMPANIMENT_STOPPED_BY_PATIENT = 4;
export const ACCOMPANIMENT_STOPPED_BY_PSYCHOLOGIST = 5;
export const ACCOMPANIMENT_CANCELED_NO_PAYMENT = 6;
export const ACCOMPANIMENT_CANCELED_BY_PATIENT = 7;
export const ACCOMPANIMENT_CANCELED_BY_PSYCHOLOGIST = 8;
export const ACCOMPANIMENT_CANCELED_BY_ADMIN = 9;
export const ACCOMPANIMENT_FINALIZED = 10;
