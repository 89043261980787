import PropTypes from 'prop-types';
import React from 'react';

export default function ImageCard({ title, Icon, className, children, callback }) {
  return (
    <div
      role="button"
      className={`flex justify-between items-center w-full p-6 z-10 rounded-20px cursor-pointer ${className}`}
      onClick={callback}
      onKeyDown={callback}
      tabIndex={0}
    >
      <div className="flex flex-col w-3/4">
        <span className="text-white font-body font-bold">{title}</span>
        {children}
      </div>
      <div className="rounded-full z-20">{Icon}</div>
    </div>
  );
}

ImageCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  Icon: PropTypes.any.isRequired,
  callback: PropTypes.func,
};

ImageCard.defaultProps = {
  className: 'bg-primary',
};
