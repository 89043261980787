import PropTypes from 'prop-types';

export default function Divider({ text, className, borderClassName, textColor, centered }) {
  return (
    <div className={`relative ${className}`}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className={`w-full border-t ${borderClassName}`} />
      </div>
      <div className={`relative flex ${centered && 'justify-center'}`}>
        <span className={`px-2 bg-white text-sm ${textColor}`}>{text}</span>
      </div>
    </div>
  );
}

Divider.propTypes = {
  text: PropTypes.string,
  textColor: PropTypes.string,
  borderClassName: PropTypes.string,
  className: PropTypes.string,
  centered: PropTypes.bool,
};

Divider.defaultProps = {
  textColor: 'text-gray-500',
  centered: true,
  borderClassName: 'border-gray-300',
};
