const {
  SESSION_CREATED,
  SESSION_SCHEDULED,
  SESSION_PAYED,
  SESSION_UNPAYED,
  SESSION_CANCELED,
  SESSION_IN_PROGRESS,
  SESSION_TIME_COMPLETED,
  SESSION_FINALIZED,
} = require('utils/constants/session');

export const SESSION_STATUSES_MENU = [
  {
    id: SESSION_CREATED,
    name: 'Creada',
  },
  {
    id: SESSION_SCHEDULED,
    name: 'Agendada',
  },
  {
    id: SESSION_PAYED,
    name: 'Pagada',
  },
  {
    id: SESSION_UNPAYED,
    name: 'Falta de pago',
  },
  {
    id: SESSION_CANCELED,
    name: 'Cancelada',
  },
  {
    id: SESSION_IN_PROGRESS,
    name: 'En Progreso',
  },
  {
    id: SESSION_TIME_COMPLETED,
    name: 'Tiempo Completado',
  },
  {
    id: SESSION_FINALIZED,
    name: 'Finalizada',
  },
];
