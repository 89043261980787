import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

const SESSIONS_PATH = 'sessions/';
const PATH = API_URL + SESSIONS_PATH;
const PRIVATE_PATH = 'private/';

export const getDetail = async (token, id) => {
  const { data } = await axios.get(`${PATH}${id}/`, {
    headers: headers(token),
  });
  return data;
};

export const updateSession = async (token, id, body) => {
  const { data } = await axios.patch(`${API_URL}${PRIVATE_PATH}${SESSIONS_PATH}${id}/`, body, {
    headers: headers(token),
  });
  return data;
};
