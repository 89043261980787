import Button from 'components/Button';
import IconSpanItem from 'components/IconSpanItem';
import Tag from 'components/Tag';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Depending if ButtonInfo exists we are going to render a first or second button
 * or change some classes.
 */

/**
 * Depending of infoDescription array we are going to render all de IconSpanItems we need
 */
export default function ContactCard({
  title,
  tag,
  tagColor,
  buttonInfo,
  withDot,
  children,
  infoDescription,
  className,
}) {
  const renderInfoDescriptionItems = () => {
    if (infoDescription !== undefined) {
      let itemsArray = [];
      infoDescription.map((item) => {
        //We have 2 items, icon and text
        itemsArray.push(
          <IconSpanItem key={Math.random()} icon={item['icon']}>
            {item['children']}
          </IconSpanItem>,
        );
      });
      return itemsArray;
    }
  };
  return (
    <>
      <div className={`px-6 py-4 min-h-128 ${className}`}>
        <div className="flex items-center min-h-40 justify-between pb-2">
          <p className="font-body m-0 font-bold text-xs lg:text-sm mr-2">{title}</p>
          <Tag selected tag={tag} color={tagColor} withDot={withDot} />
        </div>
        <div className="flex items-center justify-between">
          <div>{infoDescription && renderInfoDescriptionItems()}</div>
          {children}
        </div>
        <div></div>
      </div>
      {buttonInfo && (
        <div className="flex border-t justify-between">
          <div className={`${buttonInfo[1] ? 'w-2/4 border-r' : 'w-full'}`}>
            <Button
              onClick={buttonInfo[0].onClickEvent}
              boxShadow={false}
              icon={buttonInfo[0].icon}
              className="text-primary font-medium"
            >
              {buttonInfo[0].text}
            </Button>
          </div>
          {buttonInfo[1] && (
            <div className="w-2/4">
              <Button
                onClick={buttonInfo[1].onClickEvent}
                boxShadow={false}
                icon={buttonInfo[1].icon}
                className="text-primary font-medium"
              >
                {buttonInfo[1].text}
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

ContactCard.propTypes = {
  title: PropTypes.string.isRequired,
  imgSrc: PropTypes.string,
  tagColor: PropTypes.string,
  tag: PropTypes.string,
  className: PropTypes.string,
  buttonInfo: PropTypes.array,
  infoDescription: PropTypes.array,
  withDot: PropTypes.bool,
  children: PropTypes.any,
  onClick: PropTypes.any,
};

ContactCard.defaultProps = {};
