import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const auth = atom({
  key: 'auth',
  default: {
    user: {},
    token: null,
  },
  effects_UNSTABLE: [persistAtom],
});

export const getToken = selector({
  key: 'getToken',
  get: ({ get }) => get(auth)?.token,
});

export const getUser = selector({
  key: 'getUser',
  get: ({ get }) => get(auth)?.user,
});
