import PropTypes from 'prop-types';
import React from 'react';

export default function BasicLayout({ title, subtitle, children, button }) {
  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="grid grid-cols-1 md:grid-cols-12 items-center">
          <div className="col-span-1 md:col-span-8 mb-4 md:mb-0">
            <h1 className="text-2xl font-body font-semibold text-gray-900">{title}</h1>
          </div>
          <div className="col-span-1 md:col-span-4 md:flex md:justify-end">{button}</div>
        </div>
        <p className="text-sm font-normal text-gray-500 leading-5">{subtitle}</p>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-4">{children}</div>
      </div>
    </>
  );
}

BasicLayout.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.any.isRequired,
  button: PropTypes.any,
};
