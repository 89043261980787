import { yupResolver } from '@hookform/resolvers/yup';
import BasicLayout from 'components/BasicLayout';
import Button from 'components/Button';
import Input from 'components/Input';
import Loader from 'components/Loader';
import React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { CreateCompanySchema } from 'schemas/company';
import { createCompany } from 'services/companies';
import { getToken } from 'state/auth';

export default function CompanyCreate() {
  // Global states
  const token = useRecoilValue(getToken);

  // RHF
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(CreateCompanySchema),
  });

  // React Router
  const navigate = useNavigate();

  // React Query
  const createCompanyMutation = useMutation(
    (data) => {
      createCompany(token, data);
    },
    {
      onSuccess: () => {
        toast.success('Se creó la compañia con exito.');
        reset();
      },
      onError: () => toast.error('Algo salio mal.'),
    },
  );

  const onSubmit = (data) => {
    createCompanyMutation.mutate(data);
  };

  return (
    <BasicLayout
      title="Crear Compañia"
      button={
        <Button
          disabled={createCompanyMutation.isLoading}
          primary={true}
          className="border max-w-40% text-white font-medium rounded-md text-sm"
          type="submit"
        >
          Guardar
        </Button>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)} method="POST" className="space-y-4">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="col-span-1 space-y-4">
            <Input
              id="name"
              textLabel="Nombre"
              type="text"
              register={register}
              registerConfig={{ required: true }}
              withLabel
              error={errors.name?.message}
            />
            <div className="w-full lg:flex lg:justify-end">
              <Button
                disabled={createCompanyMutation.isLoading}
                type="submit"
                className="btn-tertiary rounded-md w-full lg:w-48"
                icon={
                  createCompanyMutation.isLoading ? (
                    <>
                      <Loader className="text-white h-6 w-6" /> Guardando
                    </>
                  ) : null
                }
              >
                {createCompanyMutation.isLoading ? '' : 'Guardar'}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </BasicLayout>
  );
}
