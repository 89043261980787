// User status
export const USER_STATUS_CREATED = 0;
export const USER_STATUS_NO_ACTIVITIES = 1;
export const USER_STATUS_IN_ACCOMPANIMENT_REQUEST = 2;
export const USER_STATUS_IN_ACCOMPANIMENT = 3;

// Autodiagnosis status
export const AUTODIAGNOSIS_CREATED = 0;
export const AUTODIAGNOSIS_IN_PROGRESS = 1;
export const AUTODIAGNOSIS_COMPLETED = 2;

// Session status
export const SESSION_CREATED = 0;
export const SESSION_SCHEDULED = 1;
export const SESSION_PAYED = 2;
export const SESSION_UNPAYED = 3;
export const SESSION_CANCELED = 4;
export const SESSION_IN_PROGRESS = 5;
export const SESSION_TIME_COMPLETED = 6;
export const SESSION_FINALIZED = 7;
