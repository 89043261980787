import * as Yup from 'yup';

// CONSTANTS
const requiredField = 'Este campo es obligatorio';

// SCHEMAS
export const CreateCompanySchema = Yup.object().shape({
  name: Yup.string().required(requiredField),
});

export const FilterCompanyPatientSchema = Yup.object().shape({
  search: Yup.string(),
});

export const AssociateCompanyPatientsSchema = Yup.object().shape({
  patients: Yup.array()
    .of(
      Yup.object().shape({
        email: Yup.string().email('Invalid email format').required('Email is required'),
      }),
    )
    .min(1, 'At least one patient is required'),
});
