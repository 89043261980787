export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isObjectEmpty = (object) => {
  return Object.keys(object).length === 0 ? true : false;
};

export const isArrayEmpty = (array) => {
  return array.length === 0 ? true : false;
};
