import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { Fragment, useRef } from 'react';

import Button from './Button';
import Loader from './Loader';

export default function Modal({
  type,
  title,
  description,
  confirmButtonText,
  confirmButtonCallback,
  cancelButtonText,
  cancelButtonCallback,
  Icon,
  open,
  setOpen,
  isLoading,
  isSuccess,
}) {
  const cancelButtonRef = useRef(null);
  const STYLES = {
    info: {
      iconContainerBg: 'bg-indigo-100',
      iconText: 'text-indigo-600',
      buttonConfirmBg: 'bg-indigo-600',
      buttonConfirmBgHover: 'bg-indigo-700',
      buttonConfirmRing: 'ring-indigo-500',
    },
    success: {
      iconContainerBg: 'bg-green-100',
      iconText: 'text-green-600',
      buttonConfirmBg: 'bg-indigo-600',
      buttonConfirmBgHover: 'bg-indigo-700',
      buttonConfirmRing: 'ring-green-500',
    },
    warning: {
      iconContainerBg: 'bg-yellow-100',
      iconText: 'text-yellow-600',
      buttonConfirmBg: 'bg-yellow-600',
      buttonConfirmBgHover: 'bg-yellow-700',
      buttonConfirmRing: 'ring-yellow-500',
    },
    error: {
      iconContainerBg: 'bg-red-100',
      iconText: 'text-red-600',
      buttonConfirmBg: 'bg-red-600',
      buttonConfirmBgHover: 'bg-red-700',
      buttonConfirmRing: 'ring-red-500',
    },
  };
  const style = STYLES[String(type)];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div
                    className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${style.iconContainerBg} `}
                  >
                    <Icon className={`h-6 w-6 ${style.iconText}`} aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{description}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <Button
                    disabled={isLoading}
                    className={`inline-flex w-full justify-center rounded-md border border-transparent ${style.buttonConfirmBg} px-4 py-2 text-base font-medium text-white shadow-sm hover:${style.buttonConfirmBgHover} focus:outline-none focus:ring-2 focus:${style.buttonConfirmRing} focus:ring-offset-2 sm:col-start-2 sm:text-sm`}
                    onClick={() => {
                      confirmButtonCallback();
                      setOpen(false);
                    }}
                    icon={isLoading ? <Loader className="h-6 w-6 text-white" /> : null}
                  >
                    {isLoading ? 'Procesando' : confirmButtonText}
                  </Button>

                  <Button
                    disabled={isLoading || isSuccess}
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => {
                      if (cancelButtonCallback) {
                        cancelButtonCallback();
                      }
                      setOpen(false);
                    }}
                    ref={cancelButtonRef}
                  >
                    {cancelButtonText}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.propTypes = {
  type: PropTypes.oneOf(['info', 'success', 'error', 'warning']),
  title: PropTypes.string.isRequired,
  description: PropTypes.any.isRequired,
  confirmButtonText: PropTypes.string,
  confirmButtonCallback: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string,
  cancelButtonCallback: PropTypes.func,
  Icon: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
};

Modal.defaultProps = {
  confirmButtonText: 'Confirmar',
  cancelButtonText: 'Cancelar',
  cancelButtonCallback: null,
  isLoading: false,
  isSuccess: false,
};
