import axios from 'axios';
import { API_URL } from 'utils/constants';
import { headers } from 'utils/headers';

import { uploadPhoto } from './generics';

const HOPS_PATH = 'head_of_mental_healths/';

export const getAllHops = async (token) => {
  const { data } = await axios.get(`${API_URL}${HOPS_PATH}`, {
    headers: headers(token),
  });
  return data;
};

export const createHop = async (token, body, file = null) => {
  let uploadResponse;

  if (file) {
    uploadResponse = await uploadPhoto(token, file);
    body['photoURL'] = uploadResponse.url;
  }

  const { first_name, last_name, email } = body;
  const { data } = await axios.post(
    `${API_URL}${HOPS_PATH}`,
    {
      token,
      first_name,
      last_name,
      photo_URL: body['photoURL'],
      email,
    },
    {
      headers: headers(token),
    },
  );

  return data;
};
